import { useCallback, useState } from 'react'

import axios from 'axios';

import FilterChainlinkData from '../services/FilterChainlinkData';

const { ethers } = require("ethers");

function useGetChainlinkPrice() {

  const ChainlinkContracts = {
    BTC: "0x71a89e229e17b36415715eB46573634eAaEB583d",
    DAI: "0x90d2573E6B75412698680D2FF2C519E1742cD95f",
    ETH: "0xA29B3A9624bD4e74D1640e94D7211c4F6a6D5351",
    EUR: "0x51B04Bc2f7E56140576d78d7a8b079A02a9cE4f5",
    LINK: "0x7a2234637675ca42D80e4206Cc4C506ae84fb8B7",
    MATIC: "0x4d58C960C9224CD551b4724F4474b74dC898CFe9",
    SAND: "0xC87358E635ac9Eace0E2E963a8E2e3d8DEd5E3E4",
    USDC: "0x6cBb459072E0e41516f0C1E39544528A1B74eB83",
    USDT: "0x1a5B42CDd8432018B3FbfF92Be1A7bb6de333790",
  };

  const [index, setIndex] = useState("")
  const [proxy, setProxy] = useState("")
  const [USDpairs, setUSDpairs] = useState([{
    assetName: "",
    decimals: 18,
    deviationThreshold: 1,
    feedCategory: "",
    feedType: "-",
    heartbeat: "24h",
    pair: "---",
    proxy: ""
  }]);
  const [numPrice, setNumPrice] = useState(0);


  const getIndex = useCallback(async () => {
    await axios
      .get("https://cl-docs-addresses.web.app/addresses.json")
      .then((response) => {
        const pairs = FilterChainlinkData(
          response.data,
          "polygon",
          "mumbai",
          "/ usd"
        );
        pairs.unshift({
          assetName: "",
          decimals: 18,
          deviationThreshold: 1,
          feedCategory: "",
          feedType: "-",
          heartbeat: "24h",
          pair: "---",
          proxy: ""
        })
        return pairs
      }).then((pairs) => {
        setTimeout(() => {
          setUSDpairs(pairs);
        }, 500);
      }).catch((error) => {
        console.log(error);
      });

  }, []);

  const updateIndex = (bet_index) => {
    if (bet_index === 0) {
      setIndex("Index");
    } else {
      setIndex(USDpairs[bet_index].pair.split(" ")[0]);
      setProxy(USDpairs[bet_index].proxy);
    }
  }
  async function getChainPrice(bet_index) {
    if (bet_index === 0) {
      return
    } else {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(0);
      const addressPrice = ChainlinkContracts[index];
      const abiPrice = ["function getLatestPrice() public view returns (int)"];
      const PriceContract = new ethers.Contract(addressPrice, abiPrice, signer);
      const price = await PriceContract.getLatestPrice();
      setNumPrice(price / 100000000);
    }
  }

  return {
    index,
    USDpairs,
    proxy,
    numPrice,
    getIndex,
    updateIndex,
    getChainPrice
  }
}

export default useGetChainlinkPrice
