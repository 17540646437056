import axios from "axios";

export default class BetService {
  constructor() {
    this.axios = axios.create({
      baseURL: "https://api.xcrow.net/",
    });
    this.axios.defaults.headers.common = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY,
    };
    this.axios.interceptors = axios.interceptors;
  }

  /**
   *
   * @param {string} wallet
   * @param {string} type
   * @returns
   */
  async getAllBets(wallet) {
    const params = {
      schema: [
        "betid",
        "status",
        "creator",
        "description",
        "value",
        "bet_duration",
        "jury",
        "opposition",
        "index_bet_a",
      ].join(","),
      wallet,
      // type,
    };

    const response = await this.axios.get("/bets/users/" + wallet, {
      params,
    });

    return response?.data?.value.map((bet) => {
      bet.amount = Number(bet.creator.value);
      delete bet.value;
      bet.bet_duration = new Date(bet.bet_duration);
      return bet;
    });
  }

  async getCompletedBets(wallet) {
    const params = {
      schema: [
        "betid",
        "status",
        "creator",
        "description",
        "value",
        "bet_duration",
        "jury",
        "opposition",
        "index_bet_a",
      ].join(","),
      wallet,
      status: "closed",
      // type,
    };

    const response = await this.axios.get("/bets/users/" + wallet, { params });

    return response?.data?.value.map((bet) => {
      bet.amount = Number(bet.creator.value);
      delete bet.value;
      bet.bet_duration = new Date(bet.bet_duration);
      return bet;
    });
  }

  async getById(bet_id) {
    const response = await this.axios.get(`/bets/${bet_id}`);

    return response?.data?.value.map((bet) => {
      bet.opposition = Object.entries(bet.opposition || {}).map(
        ([key, value]) => ({ id: key, ...value })
      );

      return bet;
    });
  }


    /**
   *
   *
   * @param {object} data
   * @param {object} data.creator
   * @param {string} data.creator.name
   * @param {string} data.creator.wallet
   * @param {number} data.creator.value
   * @param {string} data.description
   * @param {Date} data.bet_duration
   * @param {string} data.participant_type
   * @param {string} data.index_bet_a
   * @param {string} data.bet_condition_a
   * @param {number} data.price_predicted
   * @param {string} data.contract_address
   * @param {string} data.time_frame
   * 
   * @returns {Promise}
   * 
   * 
   */
  async createBet(data) {
    data.creator.wallet = localStorage.getItem("user_addr");
    const response = await this.axios.post(
      "/bets/standard?wallet=" + data.creator.wallet,
      data
    );
    return response?.data?.value;


  }

}
