const GetURL = () => {
    const testUrl = window.location.href;
    const splitUrl = testUrl.split("/");
    const participant_bet = splitUrl[3];
    const splite = splitUrl[4].split("&", 2);
    const spliteBy = splite[0].split("=");
    const bet_id = spliteBy[1];


    return { participant_bet, bet_id }

}

export default GetURL;