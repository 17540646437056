import { useState, useCallback } from "react";

import axios from "axios";

const useGetById = (bet_id) => {

    const urlOpen = `https://api.xcrow.net/bets/${bet_id}`; //"schema=betid,creator,description,value,bet_duration" ;
    const urlClosed = `https://api.xcrow.net/bets/${bet_id}?status=closed`; //"schema=betid,creator,description,value,bet_duration" ;
    const headers = {
        headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
        },
    };

    const [isLoading, setIsLoading] = useState(false);
    const [url, setURL] = useState(urlOpen);
    const [amount, setAmount] = useState(0, []);
    const [description, setDescription] = useState("");
    const [date, setDate] = useState("");
    const [opponent, setOpponent] = useState("pending");
    const [jury, setJury] = useState("pending");
    const [opponentName, setOpponentName] = useState("");
    const [juryName, setJuryName] = useState("");
    const [status, setStatus] = useState("");
    const [participant_type, setParticipant_type] = useState("");
    const [creator, setCreator] = useState("");
    const [creatorName, setCreatorName] = useState("");
    const [index, setIndex] = useState();
    const [winners, setWinners] = useState("pending");
    const [comparison, setComparison] = useState("");
    const [prediction, setPrediction] = useState(0);
    const [validity, setValidity] = useState("");
    const [oracle, setOracle] = useState("");

    const getData = useCallback(async (loading) => {
        if(loading ==="loading")
        {
            setIsLoading(true);
        }
        await axios
            .get(url, headers).then((bets) => {
                    setIsLoading(false);
                if (bets.data.value[0] === undefined) {
                    setURL(urlClosed)
                    console.log(url)
                }
                else {
                    setAmount(bets.data.value[0].creator.value);
                    setDescription(bets.data.value[0].description);
                    setDate(bets.data.value[0].bet_duration);
                    setStatus(bets.data.value[0].status);
                    setParticipant_type(bets.data.value[0].participant_type);
                    setCreator(bets.data.value[0].creator.wallet);
                    setCreatorName(bets.data.value[0].creator.name);
                    if (bets.data.value[0].jury) {
                        setJury(Object.keys(bets.data.value[0].jury)[0]);
                        setJuryName(Object.values(bets.data.value[0].jury)[0].name);
                    }
                    if (bets.data.value[0].opposition) {
                        setOpponent(Object.keys(bets.data.value[0].opposition)[0]);
                        setOpponentName(Object.values(bets.data.value[0].opposition)[0].name);
                    }
                    if (bets.data.value[0].winners) {
                        setWinners(Object.values(bets.data.value[0].winners)[0].name);
                    }
                    if (bets.data.value[0].contract_address) {
                        setOracle(bets.data.value[0].contract_address);
                        setComparison(bets.data.value[0].bet_condition_a);
                        setPrediction(bets.data.value[0].price_predicted * 10000000 * 10);
                        setValidity(bets.data.value[0].time_frame);
                        setIndex(bets.data.value[0].index_bet_a);
                    }
                 
                    
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, [url, headers, amount]);

    return {
        isLoading,
        amount,
        description,
        date,
        opponent,
        jury,
        opponentName,
        juryName,
        status,
        participant_type,
        creator,
        creatorName,
        index,
        winners,
        url,
        oracle,
        comparison,
        prediction,
        validity,
        getData
    };
}

export default useGetById;