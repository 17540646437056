import { useState } from "react";

import { useNavigate } from "react-router-dom";

import jbfArtifact from "../abis/JuriBetFactory.json";
import PutAcceptBet from "../services/PutAcceptBet";

const { ethers } = require("ethers");

const useJuryBetCreate = (
    bet_id,
    wallet,
    participant_bet,
    isStandard,
    contractAddress,
    amount,
    description,
    date,
    creator,
    jury,
    participant_type1,
    participant_group1,
    username
) => {

    const navigate = useNavigate();

    const [dataModalJury, setDataModal] = useState("")
    const [modalShowJury, setModalShow] = useState(false);
    const [errMsgJury, setErrMsgJury] = useState("")

    const putData = PutAcceptBet(bet_id, wallet, participant_type1, participant_group1, username)

    if (participant_bet === "bet_opponent" && isStandard === false) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
        const providerBet = new ethers.providers.JsonRpcProvider(
            `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_API_KEY}`
        );
        const address = "0xd68935f3cbAE00d08CCB2Ec3bD1083a2C36f0382";
        const abi = [
            "function approve(address _spender, uint256 _value) public returns (bool success)",
            "function allowance(address owner, address spender) external view returns (uint256)",
        ];
        //TEST WALLET KEY
        const addressBet = contractAddress.jbfactory;
        const abiBet = jbfArtifact.abi;
        const privateKey = process.env.REACT_APP_MUMBAI_PRIVATE_KEY;
        const signerjbf = new ethers.Wallet(privateKey, providerBet);
        const jbfContract = new ethers.Contract(addressBet, abiBet, signerjbf);

        const signer = provider.getSigner(0);
        const tokenContract = new ethers.Contract(address, abi, signer);

        const approve = async () => {
            // const balance = await provider.getBalance(wallet)
            // console.log(ethers.utils.formatEther(balance))
            try {
                const tokenWithWallet = tokenContract.connect(signer);

                const tx = await tokenWithWallet.approve(
                    addressBet,
                    ethers.utils.parseEther(amount.toString())
                );

                console.log(tx);
                return true
            }
            catch (error) {
                console.log(error.message)
                setErrMsgJury(error.message)
                setModalShow(false)
            }


        }

        const createJB = async () => {
            const jbfcontractWithWallet = jbfContract.connect(signerjbf);
            const createBetBlock = await jbfcontractWithWallet.createBet(
                bet_id,
                description,
                ethers.utils.parseEther(amount.toString()),
                date,
                creator,
                jury,
                wallet
            );
            console.log(createBetBlock);
            return true
        };

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const chainCreateJuryBet = async () => {
            try {
                setModalShow(true)
                setDataModal("1/2 Waiting for the approval..")
                await approve().then(async (response) => {
                    await delay(20000)
                    if (response === true) {
                        await createJB().then(async (response) => {
                            console.log(response)
                            setDataModal("1/2 Bet being created")
                            await delay(20000)
                            if (response === true) {
                                await putData().then(() => {
                                    setModalShow(false)
                                    navigate(`/my-open-bets/${bet_id}`, { state: { bet: bet_id } })
                                })
                            }
                        });
                    }
                })


                // console.log("approve");
                // await createSB();
                // console.log("createSB");
                // await addUpkeep();
                // console.log("keeperCreated");
                // await putData().then(() => {
                //     navigate(`/my-open-bets/${bet_id}`, { state: { bet: bet_id } })
                // })
            } catch (e) {
                console.log(e)
                setModalShow(false)
            }
        };

        return {
            dataModalJury,
            modalShowJury,
            errMsgJury,
            chainCreateJuryBet
        }
    }

    else if (participant_bet !== "bet_opponent") {
        if (jury === "pending") {
            const chainCreateJuryBet = () => {
                setModalShow(true)
                setDataModal("Wow! You will be the Jury, make your best to be fair!")
                putData();
                setTimeout(() => {
                    navigate(`/my-open-bets/${bet_id}`, { state: { bet: bet_id } });
                }, 5000);
            }

            return {
                dataModalJury,
                modalShowJury,
                errMsgJury,
                chainCreateJuryBet
            }
        } else {
            const chainCreateJuryBet = () => {
                setErrMsgJury("Jury role has already been taken, maybe next time you can have such glourious responsability")
            }
            return {
                dataModalJury,
                modalShowJury,
                errMsgJury,
                chainCreateJuryBet
            }
        }
    }

    else {
        const chainCreateJuryBet = () => {
            return;
        }
        return {
            dataModalJury,
            modalShowJury,
            errMsgJury,
            chainCreateJuryBet
        }
    }
}

export default useJuryBetCreate;