import React, { useState, useContext } from "react";

import { Modal } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { BsPatchCheck } from "react-icons/bs";

import ActiveWalletContext from "../Wallet/ActiveWallet.context";

import AlertDanger from "./AlertDanger";

const { ethers } = require("ethers");

export default function PopUpSuccess() {
  const [show, setShow] = useState(false);
  const [isLoading] = useState(false);

  const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
  const privateKey = process.env.REACT_APP_MUMBAI_PRIVATE_KEY;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;


  const [errMsg, setErrMsg] = useState("");



  const address = "0xd68935f3cbAE00d08CCB2Ec3bD1083a2C36f0382";

  const getTokensHandler = () => {
    try {
      const providerBet = new ethers.providers.JsonRpcProvider(
        `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_KEY}`
      );
      const abi = [
        "function mint(address to, uint256 amount) public onlyOwner returns (bool success)",
      ];

      const signersbf = new ethers.Wallet(privateKey, providerBet);

      const tokenContract = new ethers.Contract(address, abi, signersbf);


      const mint = async () => {
        // const balance = await provider.getBalance(wallet)
        // console.log(ethers.utils.formatEther(balance))

        const tokenWithWallet = tokenContract.connect(signersbf);

        const tokenAmount = 5;

        const tx = await tokenWithWallet.mint(
          wallet,
          ethers.utils.parseEther(tokenAmount.toString())
        );

        console.log(tx);

      };
      mint();
      handleShow();

    } catch (error) {
      console.log(error.message)
      setErrMsg(error.message)
    }
  };

  return (
    <>

      <div className="text-end float-end">
        <Button
          variant="primary xcrowcreate"
          disabled={isLoading}
          onClick={!isLoading ? getTokensHandler : null}
        >
          <BsPatchCheck />
          {isLoading ? "Loading…" : "Get Tokens"}
        </Button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="popbox"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Choose the type of Xcrow to create:</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="cardx mb-4 box-shadow">
                  <div className="cardx-header text-center">
                    {errMsg !== "" && AlertDanger(errMsg)}
                    <Alert variant="success">
                      5 Tokens have been added to your wallet
                    </Alert>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </Modal.Body>
      </Modal>

    </>
  );
}
