import React, { useContext, useEffect } from "react";

import axios from "axios";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ActiveWalletContext from "../Wallet/ActiveWallet.context";

const { ethers } = require("ethers");

function StandardGrowthForm() {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: { num_index: 1 },
  });
  const navigate = useNavigate();
  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;

  const formValue = watch();

  const coin = formValue.bet_index;


 
  const description = `I ${formValue.bet_userName} bet ${formValue.bet_value} that the price of ${formValue.bet_index} will be ${formValue.bet_comparison} than ${formValue.bet_growthpredicted} USD ${formValue.bet_validity} ${formValue.bet_date}.`;

  const postUrl = "https://api.xcrow.net/bets/standard?wallet=" + wallet;
  const data = {
    creator: {
      name: formValue.bet_userName,
      value: Number(formValue.bet_value),
    },
    index_bet_a: formValue.bet_index,
    bet_condition_a: formValue.bet_comparison,
    growth_predicted: Number(formValue.bet_growthpredicted),
    time_frame: formValue.bet_validity,
    bet_duration: new Date(formValue.bet_date).getTime(),
    description,
    participant_type: "opponent",
  };

  async function submitBet() {
    data.creator.wallet = localStorage.getItem("user_addr");
    axios.post(postUrl, data).then((response) => {
      console.log(response.data);
      /////////////blockchain side //////////////
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const address = "0x62Dd3378b677312789C24bF2A890B2C2e98AC9C8";
      const abi = [
        "function approve(address _spender, uint256 _value) public returns (bool success)",
        "function allowance(address owner, address spender) external view returns (uint256)",
      ];
      const signer = provider.getSigner(0);
      const tokenContract = new ethers.Contract(address, abi, signer);
      const test = async () => {
        const tokenWithWallet = tokenContract.connect(signer);

        const tx = await tokenWithWallet.approve(
          "0x5cc7B416014Df649Aa57C04B50953732Cbc15F6B",
          ethers.utils.parseEther(formValue.bet_value)
        );

        console.log(tx);

        setTimeout(() => {
          navigate(`/bet_standard_share/${response.data.value}`, {
            state: { bet: response.data.value },
          });
        }, 1000);
      };
      test();
      console.log(response.data.value);
    });
  }

  useEffect(() => {
  
    const interval = setInterval(() => {
     
    }, 15000);
    return () => clearInterval(interval);
  }, [coin]);

  return (
    <div>
      <p>
      Standard growth smart disputes offer you the possibility to make any comparison about the growth of any index.
      </p>
      <Form onSubmit={handleSubmit(submitBet)}>
        <Form.Group>
          <Form.Label htmlFor="bet_value">Name</Form.Label>
          <Form.Control
            type="string"
            placeholder="Joe"
            id="bet_userName"
            {...register("bet_userName", { required: true })}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label htmlFor="bet_value">Bet value</Form.Label>
          <Form.Control
            type="number"
            placeholder="1000USD"
            id="bet_value"
            {...register("bet_value", { required: true })}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label htmlFor="bet_index">Index</Form.Label>
          <Form.Select
            name="bet_index"
            label="index"
            id="bet_index"
            {...register("bet_index", { required: true })}
          >
            <option>BTC</option>
            <option>ETH</option>
          </Form.Select>
          
        </Form.Group>

        <Form.Group>
          <Form.Label htmlFor="bet_type">Percentage (%)</Form.Label>
          <Row>
            <Col>
              <Form.Select
                name="bet_type"
                id="bet_comparison"
                {...register("bet_comparison", { required: true })}
              >
                <option>increase</option>
                <option>decrease</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Control
                type="string"
                placeholder="10%"
                id="bet_growthpredicted"
                {...register("bet_growthpredicted", { required: true })}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Form.Label htmlFor="bet_validity">Validity</Form.Label>
          <Form.Select
            name="bet_validity"
            id="bet_validity"
            {...register("bet_validity", { required: true })}
          >
            <option>until</option>
            <option>on</option>
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label htmlFor="bet_date">
            Date
          </Form.Label>
          <Form.Control
            type="date"
            placeholder="POLITICS_BET_DATE_INPUT_PLACEHOLEDER"
            id="bet_date"
            {...register("bet_date", { required: true })}
          />
        </Form.Group>

        <Button type="submit">Create bet</Button>
      </Form>
      I {formValue.bet_userName},&nbsp; bet {formValue.bet_value}&nbsp; that the
      price of {formValue.bet_index}&nbsp; will {formValue.bet_comparison}
      &nbsp; more than {formValue.bet_growthpredicted}&nbsp;{"% "}
      {formValue.bet_validity}&nbsp;
      {formValue.bet_date}.
    </div>
  );
}

export default StandardGrowthForm;
