import React, { useContext } from "react";

import { Alert, Button, Container } from "react-bootstrap";

import ActiveWalletContext from "../../components/Wallet/ActiveWallet.context";
import EthereumClient from "../../utils/EthereumClient";

const addMumbai = async () => {

  const chainId = '0x13881';
  const rpcURL = 'https://matic-mumbai.chainstacklabs.com';
  const networkName = 'Mumbai Testnet';
  const currencyName = 'MATIC';
  const currencySymbol = 'MATIC';
  const explorerURL = 'https://mumbai.polygonscan.com/';

  await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: chainId,
          chainName: networkName,
          rpcUrls: [rpcURL],
          blockExplorerUrls: [explorerURL],
          nativeCurrency: {
            name: currencyName,
            symbol: currencySymbol, 
            decimals: 18,
          },
        },
      ],
    });
    window.alert("Mumbai network has been added to your metamask.")
    window.location.reload();
    
}

function CheckWallet(props) {
  const { network } = useContext(ActiveWalletContext);

  return (
    <div>
      <div className="temp">
        <button
          className="btn btn-primary-webet"
          type="button"
          onClick={EthereumClient.requestAccount}
        >
          Connect Wallet
        </button>
      </div>
      {network !== "80001" && (<Alert>Please connect to Mumbai testnet</Alert>)}
      <Container className="text-center">
        <Button className="btn btn-primary-webet" onClick={addMumbai}>
          Add Mumbai network
        </Button>

      </Container>
    </div>
    /*  add logic to check if user if logged... */

    //  <div className="container mobile_container">
    //     <div className="row justify-content-md-center">
    //       <div className="col-12 text-center" />
    //       <div className="col-6 p-4 text-center connect-wallet">
    //         <i className="bi bi-wallet2" />
    //         <p>Please connect to your wallet.</p>
    //         <button
    //           className="btn-primary-webet"
    //           type="button"
    //           onClick={EthereumClient.requestAccount}
    //         >
    //           Connect Wallet
    //         </button>
    //       </div>
    //     </div>
    //     <div className="body-bg-gradient" />
    //   </div>
  );
}
export default CheckWallet;
