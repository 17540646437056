import React, { useState, useCallback, useEffect, useContext} from 'react'

import axios from 'axios';
import { Card, Container} from "react-bootstrap";
import { useLocation} from "react-router-dom";

import ribbon from "../assets/images/ribbon.png"
import ActiveWalletContext from "../components/Wallet/ActiveWallet.context";




export default function NftPage() {

  const location = useLocation();
  const bet_id = location.state.bet;

  console.log(bet_id);

  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;



    const url = "https://api.xcrow.net/bets/" + bet_id + "/?status=closed";
    const headers = {
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
};

const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");

  const [creatorName, setCreatorName] = useState("");
  
  const [opponentName, setOpponentName] = useState("");

  const [winners, setWinners] = useState("pending");
  const [pos, setPos] = useState("");
  const [isStandard, setisStandard] = useState(false);
  const [index, setIndex] = useState();

const getData = useCallback(async () => {
    await axios
      .get(url, headers)
      .then((bets) => {
        console.log(bets.data.value[0]);
        setIndex(bets.data.value[0].index_bet_a);
        if (index !== undefined) {
          setisStandard(true);
        }
        setAmount(bets.data.value[0].creator.value);
        setDescription(bets.data.value[0].description);
        setDate(bets.data.value[0].bet_duration);
        
        setCreatorName(bets.data.value[0].creator.name);
        if (isStandard === false) {
  
         
         
          setOpponentName(Object.values(bets.data.value[0].opposition)[0].name);
          setWinners(Object.keys(bets.data.value[0].winners)[0]);
        } else {
        
          setOpponentName(Object.values(bets.data.value[0].opposition)[0].name);
          setWinners(Object.keys(bets.data.value[0].winners)[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [headers, url]);

  const position = () => {
    if (wallet === winners) {
      setPos("Congrats, you won!!");
    } else {
      setPos("I'm sorry you loss, better luck next time");
    }
  };

  useEffect(() => {
    getData();
    position();
  }, [getData, position]);



  const dDate = new Date(date)

  const month = dDate.toLocaleString("en-US", { month: "long" });
  const hour = dDate.toLocaleString("en-US", { hour: "2-digit", minute: "2-digit" });
  const day = dDate.toLocaleString("en-US", { day: "2-digit" });
  const year = dDate.getFullYear();
  const timeZone = dDate.toLocaleString("en-US", { timeZoneName: "short" }).slice(-5)

  const currrentDate = new Date()
    let date_now = currrentDate.getDate().toLocaleString("en-US", { day: "2-digit" });
    let month_now = currrentDate.getMonth().toLocaleString("en-US", { month: "long" });
    let year_now = currrentDate.getFullYear();
  return (

    <>
    <Container className="mt-5 ">
   
      <Card className="text-center certificate-box">
      <img src={ribbon}
            className="ribbon"
            />
        <Card.Body>
           
          <Card.Title className='mt-5'>
              {pos} {creatorName} has won a dispute against {opponentName}
              </Card.Title>
          <Card.Text>
              <br/>
            You have bet in favor of this statement:  {description} and won {amount} $
          </Card.Text>
          <Card.Text>
          Dispute finish date: {day}/{month}/{year} {hour} {timeZone}
          </Card.Text>        
          <br />
        </Card.Body>  
        <div className='left-italic ms-4 mb-2'>
            Signed by Xcrow
        </div>
        <Card.Footer className="text-muted-bet">
           Certificate date: {year_now}/{month_now}/{date_now}
        </Card.Footer>
      </Card>
    </Container>
  </>
  )
}
