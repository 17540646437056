import React, { useEffect, useRef} from "react";

import { Button, Form, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Loading1 from "../components/UI/Loading1"
import PopLink from "../components/UI/PopLink";
import PopQR from "../components/UI/PopQR";
import useFormatedData from "../hooks/useFormatedDate";
import useGetById from "../hooks/useGetById";
import useGetQRs from "../hooks/useGetQRs";

export default function JuryBetShare() {

 
  const navigate = useNavigate();
  const location = useLocation();
  const bet_id = location.state.bet;

  const ref = useRef(null);

  const { linkJury, linkOpponent1, qrJury, qrOpponent1, btn, generateQR, isLoading } = useGetQRs(bet_id)
  const { amount, description, date, opponent, jury, opponentName, juryName, getData } = useGetById(bet_id)
  

  const { finalDate } = useFormatedData(date)

  const BetAccepted = () => {
    if (opponent !== "pending") {
      setTimeout(() => {
        navigate(`/my-open-bets/${bet_id}`, { state: { bet: bet_id } });
      }, 2000);
    }
  };

  
  

  useEffect(() => {
   
    getData();
    generateQR();  
  }, [generateQR]);

  
  useEffect(() => {
   
    const interval = setInterval(() => {
      getData();
      BetAccepted();
    }, 20000);
    return () => clearInterval(interval);
  }, [opponent, jury]);

  return (
    <>
    <Loading1 loaded={isLoading}>
    <div><div className="container betcontainer col-9 betsummary">
      <div className="container  col-10">
        <div className="row">
          <div className="col-sm">
            You are challenging for the amount of {amount} USD.
          </div>
        </div>
        <div className="row">
          <div>
            Description:  {description}
          </div>
          <div>Finish Date:  {finalDate}</div>
        </div>
        <Form>
          <Form.Group>
            <Row>
              <Col>
                <div className="col-sm">
                  <Form.Label>Jury Link:</Form.Label>
                  <Form.Label>{juryName}</Form.Label>
                  <Form.Control placeholder={jury} disabled />
                </div>
              </Col>
              <Col className="text-center">
                <div ref={ref}>
                  <PopLink
                    ref={ref}
                    btn={btn}
                    link={linkJury}
                    data={"Jury"}
                  />
                  <PopQR
                    btn={btn}
                    qrImage={`https://xcrow.net${qrJury}`}
                    data={"Jury"}
                  />
                </div>
              </Col>
            </Row >
          </Form.Group >
          <Form.Group className="mb-3">
            <Row>
              <Col>
                <Form.Label>Opponent Link:</Form.Label>
                <Form.Label>{opponentName}</Form.Label>
                <Form.Control placeholder={opponent} disabled />
              </Col>
              <Col className="text-center">
                <div>
                  <PopLink
                    ref={ref}
                    btn={btn}
                    link={linkOpponent1}
                    data={"Opponent"}
                  />
                  <PopQR
                    btn={btn}
                    qrImage={`https://xcrow.net${qrOpponent1}`}
                    data={"Opponent"}
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
        </Form >
      </div >
    </div >
      <div className="text-center">
        <div className="d-grisd gap-2">
          <Button variant="info" size="xs">
            Share on Twitter
          </Button>
          <Button variant="info" size="xs">
            Share on Facebook
          </Button>
        </div>
      </div>
    </div >
    </Loading1>
    </>
  );
}
