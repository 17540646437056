import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsPatchCheck } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import { BsGraphUp } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function PopUp() {
  const [show, setShow] = useState(false);
  const [isLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="text-end float-end">
        <Button
          variant="primary btn-lg xcrowcreate"
          disabled={isLoading}
          onClick={!isLoading ? handleShow : null}
        >
          <BsPatchCheck />
          {isLoading ? "Loading…" : "Create new Dispute"}
        </Button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="popbox"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Choose the type of Xcrow to create:</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="cardx mb-4 box-shadow">
                  <div className="cardx-header text-center">
                    <h4 className="my-0 font-weight-normal">
                      {" "}
                      <BsPeople /> Jury Dispute
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Wanna bet with your friends and have a third party friend settle the result? Make sure you trust the jury picked since he will decide the outcome.
                    </p>
                    <div className="text-center">
                      <Link to="/bet-create" className="btn btn-primary color-blue">
                        Create Jury Dispute
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                {" "}
                <div className="cardx mb-4 box-shadow">
                  <div className="cardx-header text-center">
                    <h4 className="my-0 font-weight-normal">
                      <BsGraphUp /> Standard Dispute
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Simple disputes 1vs1 where the result is settled by a decentralized oracle. You do not need to trust your opponent, the system assures that.
                    </p>
                    <div className="text-center">
                      <Link
                        to="/bet-create/standard"
                        className="btn btn-primary me-auto color-blue"
                      >
                        Create Standard Dispute
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
