import { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import contractAddress from "../abis/contract-address.json";
import sbfArtifact from "../abis/StandardBetFactoryKeeper.json";

const { ethers } = require("ethers");

const useCheckWinner = (bet_id, opponent, creator, opponentName, creatorName, wallet, amount) => {

    const navigate = useNavigate();

    const [winners, setWinners] = useState("pending");

    const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
    const providerBet = new ethers.providers.JsonRpcProvider(
        `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_API_KEY}`
    );

    const addressBet = contractAddress.sbfactory;
    const abiBet = sbfArtifact.abi;
    const privateKey = process.env.REACT_APP_MUMBAI_PRIVATE_KEY;

    const abiInstance = ["function winner() view returns (address)"];

    const signersbf = new ethers.Wallet(privateKey, providerBet);
    const sbfContract = new ethers.Contract(addressBet, abiBet, signersbf);

    const getBetAddress = async () => {
        const sbfcontractWithWallet = sbfContract.connect(signersbf);
        const addressInstance = await sbfcontractWithWallet.betAddress(bet_id);
        console.log(addressInstance);

        const betInstanceContract = new ethers.Contract(
            addressInstance,
            abiInstance,
            providerBet
        );
        // const betInstanceContractWithWallet = betInstanceContract.connect(signersbf)
        const betWinner = await betInstanceContract.winner();

        console.log(betWinner);
        console.log(opponent)
        console.log(creator === betWinner.toLowerCase());
        console.log(opponent === betWinner.toLowerCase());

        if (
            betWinner.toLowerCase() === opponent ||
            betWinner.toLowerCase() === creator
        ) {
            if (betWinner.toLowerCase() === opponent) {
                setWinners(opponentName);
            } else {
                setWinners(creatorName);
            }
            const putUrl =
                "https://api.xcrow.net/bets/" +
                bet_id +
                "/verdict/?wallet=" +
                wallet;

            const data = {
                verdict: "The oracle said " + winners + " won",
                verdict_type: "bool",
                winners: {
                    [betWinner.toLowerCase()]: {
                        name: winners,
                        value: amount.toString(),
                    },
                },
            };

            const putData = () => {
                axios.put(putUrl, data, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_API_KEY,
                    },
                });
            };
            setTimeout(() => {
                navigate(`/bet_result/${bet_id}`, { state: { bet: bet_id } });
            }, 10000);

            putData();
        }
    };



    return {
        getBetAddress
    };
}


export default useCheckWinner;