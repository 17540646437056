import React, { useEffect } from "react";

import { Button, Container, Form, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";

import StandardGrowthForm from "../components/Bets/StandardGrowthForm";
import AlertDanger from "../components/UI/AlertDanger";
import LoadingModal from "../components/UI/LoadingModal";
import useGetChainlinkPrice from "../hooks/useGetChainlinkPrice";
import usePositiveNumbers from "../hooks/usePositiveNumbers";
import useStandardBetCreateForm from "../hooks/useStandardBetCreateForm";
import DateFormInput from "../services/DateFormInput"

function CreateStandardBet() {

  const { register, watch } = useForm({
    defaultValues: {
      num_index: 1,
      bet_index: 0,
      bet_date: ""
    },
  });

  const formValue = watch();

  const {
    index,
    USDpairs,
    proxy,
    numPrice,
    getIndex,
    updateIndex,
    getChainPrice
  } = useGetChainlinkPrice()

  const {
    betvalor,
    predictedValor,
    updateInputValue,
    updatePredictedValue,
  } = usePositiveNumbers()

  const description = `I ${formValue.bet_userName} bet ${formValue.bet_value} USD that the price of ${index} will be ${formValue.bet_comparison} than ${formValue.bet_priceprediction} USD ${formValue.bet_validity} ${(formValue.bet_date === "") ? "" : formValue.bet_date.replace("T", " ")}.`;

  const { errMsg, validated, dataHook, modalShow, ApproveFlow } = useStandardBetCreateForm(formValue, index, description, proxy);

  async function submitBet(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      ApproveFlow()
    }
  }

  useEffect(() => {
    getIndex()
  }, []);

  useEffect(() => {
    updateIndex(watch("bet_index"));
    getChainPrice(watch("bet_index"))
  }, [watch("bet_index"), index, numPrice]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (watch("bet_index") !== 0) {
        getChainPrice()
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [index, numPrice]);


  return (
    <>
      <LoadingModal
        show={modalShow}
        data={dataHook}
      />
      <div className="container betcontainer col-8">
        {errMsg !== "" && AlertDanger(errMsg)}
        <div className="row">
          <div className="col col-11">
            <h1>Create Price Dispute</h1>
            <Tabs
              defaultActiveKey="price"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="price" title="Price">
                <p>
                  Standard price smart disputes offer you the possibility to make any comparison about price indexes.
                </p>
                <Form
                  noValidate
                  validated={validated}
                  // onSubmit={handleSubmit(submitBet)}
                  onSubmit={submitBet}
                >
                  <Form.Group>
                    <div className="mb-3 row">
                      <label
                        htmlFor="bet_userName"
                        className="col-sm-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="string"
                          placeholder="Joe"
                          id="bet_userName"
                          required
                          {...register("bet_userName", { required: true })}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please choose a username.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group onChange={updateInputValue}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="bet_value"
                        className="col-sm-2 col-form-label"
                      >
                        Value
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="number"
                          placeholder="1000USD"
                          id="bet_value"
                          required
                          min={1}
                          value={betvalor}
                          {...register("bet_value", { required: true })}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please choose a token amount.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="mb-3 row">
                      <label
                        htmlFor="bet_index"
                        className="col-sm-2 col-form-label"
                      >
                        Index
                      </label>
                      <div className="col-sm-10">
                        <Form.Select
                          name="bet_index"
                          label="index"
                          id="bet_index"
                          required
                          type="string"
                          {...register("bet_index", { required: true })}
                        >
                          {USDpairs.map((USDpairs, Mindex) => (
                            <option key={USDpairs.proxy} value={Mindex}>
                              {USDpairs.pair.split(" ")[0]}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please choose a token index.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <p>Current price: {numPrice} USD</p>
                  </Form.Group>
                  <Form.Group controlId="validationCustomUsername">
                    <div className="mb-3 row">
                      <label
                        htmlFor="bet_type"
                        className="col-sm-2 col-form-label"
                      >
                        Comparison
                      </label>
                      <div className="col-sm-10">
                        <Form.Select
                          name="bet_type"
                          id="bet_comparison"
                          required
                          {...register("bet_comparison", { required: true })}
                        >
                          <option>lower</option>
                          <option>greater</option>
                        </Form.Select>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please choose a comparison type. Great if xyz.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group onChange={updatePredictedValue}>
                    <div className="mb-3 row">
                      <label
                        htmlFor="bet_priceprediction"
                        className="col-sm-2 col-form-label"
                      >
                        Price predicted
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="number"
                          placeholder="40000USD"
                          id="bet_priceprediction"
                          required
                          min={1}
                          value={predictedValor}
                          {...register("bet_priceprediction", { required: true })}
                        />
                        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                        <Form.Control.Feedback type="invalid">
                          Please choose a comparison type.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="mb-3 row">
                      <label
                        htmlFor="bet_validity"
                        className="col-sm-2 col-form-label"
                      >
                        Validity
                      </label>
                      <div className="col-sm-10">
                        <Form.Select
                          name="bet_validity"
                          id="bet_validity"
                          required
                          {...register("bet_validity", { required: true })}
                        >
                          <option>until</option>
                          <option>on</option>
                        </Form.Select>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please choose a target date.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="mb-3 row">
                      <label
                        htmlFor="bet_date"
                        className="col-sm-2 col-form-label"
                      >
                        Date
                      </label>
                      <div className="col-sm-10">
                        <Form.Control
                          type="datetime-local"
                          placeholder="POLITICS_BET_DATE_INPUT_PLACEHOLEDER"
                          id="bet_date"
                          min={DateFormInput()}
                          required
                          {...register("bet_date", { required: true })}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please choose a target date.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                  <Button className="alignButton mt-3" type="submit" variant="info" size="xs">Create bet</Button>
                </Form>
                <Container className="mt-5">
                  I {formValue.bet_userName},&nbsp;bet {formValue.bet_value}&nbsp;USD that the price of {index}&nbsp;will be{" "}
                  {formValue.bet_comparison}
                  &nbsp;than {formValue.bet_priceprediction}&nbsp;USD{" "}
                  {formValue.bet_validity}&nbsp;
                  {(formValue.bet_date === "") ? "" : formValue.bet_date.replace("T", " ")}.
                </Container>
              </Tab>
              <Tab eventKey="growth" title="Growth">
                <StandardGrowthForm />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateStandardBet;