import axios from "axios";

export default class HttpClient {
  /**
   *
   * @param {{ [key: string]: string }} headers
   */
  static setDefaultHeaders(headers) {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      ...headers,
    };
  }
}
