import React, { useEffect } from "react";

import { Form, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Loading1 from "../components/UI/Loading1"
import PopLink from "../components/UI/PopLink";
import useFormatedData from "../hooks/useFormatedDate"
import useGetById from "../hooks/useGetById"
import useGetQRs from "../hooks/useGetQRs";

export default function StandardBetShare() {

  //   initializer();
  const navigate = useNavigate();
  const location = useLocation();
  const bet_id = location.state.bet;
  // const [show, setShow] = useState(false);
  // const target = useRef(null);

  //hooks//
  const { linkOpponent1, btn, generateQR, isLoading } = useGetQRs(bet_id);
  const { description, amount, opponent, opponentName, date, getData } = useGetById(bet_id);
  const { finalDate } = useFormatedData(date);


  useEffect(() => {
    if (opponentName !== "") {
      setTimeout(() => {
        navigate(`/my-open-bets/${bet_id}`, { state: { bet: bet_id } });
      }, 2000);
    } else {
      generateQR();
      getData()
    }

  }, [bet_id, navigate, opponentName])



  return (
    <>
    <Loading1 loaded={isLoading}>
    <div className="container betcontainer col-9">
      <div className="row">
        <div className="col col-11">
          <h1>New Bet</h1>
          <div className="text-center">
            <div className="xcrowaction">
              You have created a dispute for the amount of {amount} USD
            </div>
            <div className="mb-3 row">
              <div className="col-sm-2">Description: </div>
              <div className="col-sm-10 text-start">{description}</div>
              <div className="col-sm-2">Finish Date: </div>
              <div className="text-muted-webet col-sm-10 text-start">
                {finalDate}
              </div>
              <hr />
              <div className="col-sm-10 row">
                <div className="xcrowinfo">
                  Invite participants by Link or QR Code. After all participants
                  have accepted the dispute is created automatically.
                </div>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Opponent Link</Form.Label>
                    <Row>
                      <Col>
                        <Form.Label>{opponentName}</Form.Label>
                        <Form.Control placeholder={opponent} disabled />
                      </Col>
                      <Col>
                        {/* <Button className="mt-3"
                          ref={target}
                          onClick={() => {
                            navigator.clipboard.writeText(linkOpponent1);
                            setShow(!show);
                          }}
                        >
                          Copy Link
                        </Button> */}
                        <PopLink
                          btn={btn}
                          link={linkOpponent1}
                          data={"Opponent"}
                        />
                        {/* <Overlay
                          target={target.current}
                          show={show}
                          placement="top"
                          rootClose="true"
                        >
                          {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                              URL Copied??
                            </Tooltip>
                          )}
                        </Overlay> */}
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Loading1>
    </>
  );
}
