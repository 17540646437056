import { useState } from "react";

const { ethers } = require("ethers");

const useApproveFunds = (wallet, addressBet) => {

    const [dataModal, setDataModal] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const [modalShow, setModalShow] = useState(false);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

    const providerAllowance = new ethers.providers.JsonRpcProvider(
        `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_API_KEY}`
    );

    const address = "0xd68935f3cbAE00d08CCB2Ec3bD1083a2C36f0382";
    const abi = [
        "function approve(address _spender, uint256 _value) public returns (bool success)",
        "function allowance(address owner, address spender) external view returns (uint256)",
    ];
    const signer = provider.getSigner(0);
    const tokenContract = new ethers.Contract(address, abi, signer);

    const tokenContractAllowance = new ethers.Contract(address, abi, providerAllowance);

    const checkFunds = async () => {
        try {
            setModalShow(true)
            setDataModal("Checking funds approved")
            const approvedFunds = await tokenContractAllowance.allowance(
                wallet,
                addressBet
            );
            return parseInt(approvedFunds)
        } catch (error) {
            console.log(error.message)
            setErrMsg(error.message)
            setModalShow(false)
        }
    };

    const approveFunds = async (value) => {
        const tokenWithWallet = tokenContract.connect(signer);
        setDataModal("Waiting approval on metamask")
        try {
            await tokenWithWallet.approve(
                addressBet,
                ethers.utils.parseEther(value)
            );
            setDataModal("Waiting confirmation on the transaction")
            return true;
        } catch (error) {
            console.log(error.message)
            setErrMsg(error.message)
            setModalShow(false)
        }
    };

    return {
        dataHook: dataModal,
        errMsg,
        modalShow,
        setDataModal,
        checkFunds,
        approveFunds
    };
}

export default useApproveFunds;