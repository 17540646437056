import React from 'react';

import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';

LoadingModal.propTypes = {
    data: PropTypes.string,
    onHide: PropTypes.func,
    show: PropTypes.bool
};

function LoadingModal({ data, onHide, show }) {
    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Requesting to the blockchain
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="spinner-border spinner-border-sm text-info" role="status">
                    <span className="sr-only" />
                </div>
                <p>
                    {data}
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default LoadingModal;