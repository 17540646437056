import axios from "axios";

const PutAcceptBet = (bet_id, wallet, participant_type1, participant_group1, username) => {

    // bet data needs to be: the general bet(creator,description, participant type, date), leva na query string a wallet
    // request body igual à general bet mais query string de wallet nome opcional e participant type

    const putUrl =
        "https://api.xcrow.net/bets/" +
        bet_id +
        "/accept/?wallet=" +
        wallet +
        "&type=" +
        participant_type1;

    const headers = {
        headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
        },
    };

    const data = {
        participant_type: participant_type1,
        [participant_group1]: {
            [wallet]: { name: username, agree: true },
        },
    };

    const putData = async () => {
        await axios.put(putUrl, data, headers)
    }

    return (putData)
}

export default PutAcceptBet;