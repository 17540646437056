import React from "react";

import useActiveHttpRequestCounter from "../../hooks/useActiveHttpRequestCounter";


export default function Loading() {
  const activeRequests = useActiveHttpRequestCounter();
  // const activeRequestsFix = Math.abs(activeRequests);
  // const activeRequests = 0;

  return (
    <div className="spinner-outer-frame">
      <div className="spinner-inner-frame">
        {activeRequests > 0 && (
          <div className="spinner-border spinner-border-sm text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <div className="timesync">Last Synced to blockchain at {Date()}</div>
      </div>
    </div>
  );
}
