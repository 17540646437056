import React from 'react';

import Alert from 'react-bootstrap/Alert';


function AlertDanger(msg) {


    return (
      <Alert variant="danger">
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          {msg}
      </Alert>
    );
  
}
export default AlertDanger;