
const useFormatedData = (date) => {

    const dDate = new Date(date);

    const month = dDate.toLocaleString("en-US", { month: "long" });
    const hour = dDate.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
    });
    const day = dDate.toLocaleString("en-US", { day: "2-digit" });
    const year = dDate.getFullYear();
    const timeZone = dDate
        .toLocaleString("en-US", { timeZoneName: "short" })
        .slice(-4);

    const finalDate = `${day}/${month}/${year} ${hour}${timeZone}`

    return {
        finalDate
    };
}

export default useFormatedData;