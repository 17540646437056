import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import BetDate from "../UI/BetDate";
// import CardItem from "../UI/CardItem";

BetItem.propTypes = {
  bet_duration: PropTypes.object,
  description: PropTypes.string,
  amount: PropTypes.number,
  betid: PropTypes.number,
  status: PropTypes.string,
  index_bet_a: PropTypes.string,
};

function BetItem({
  bet_duration,
  description,
  amount,
  betid,
  status,
  index_bet_a,
}) {
  
  const navigate = useNavigate();
  
  return (
    <tr className="bet-clickable" onClick={()=> navigate(`/my-open-bets/${betid}`, { state: { bet: betid } })}>
 
      <td>
        <Link
          className="betlink-xcrow"
          to={`/my-open-bets/${betid}`}
          state={{ bet: betid }}
        >
          {betid}
        </Link>
      </td>
      <td>{index_bet_a !== undefined ? "Standard" : "Jury"}</td>
      <td>
        <div>
          <div>{description}</div>
        </div>
      </td>
      <td>
        <div>${amount}</div>
      </td>
      <td>{status}</td>
      <td>
        <BetDate date={bet_duration} />
      </td>
    </tr>
  );
}

export default BetItem;
