import { useState, useEffect, useCallback } from "react";

import axios from "axios";

/**
 * Hook to watch the number of active http requests
 *
 * @returns {number} number of active http request
 */
export default function useActiveHttpRequestCounter() {
  const [activeHttpRequests, setActiveHttpRequests] = useState(function (
    current
  ) {
    return !current || current <= 0 ? 0 : current;
  });

  const incrementInterceptor = useCallback(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        setActiveHttpRequests(activeHttpRequests + 1);
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => axios.interceptors.request.eject(requestInterceptor);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const decrementInterceptor = useCallback(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setActiveHttpRequests(activeHttpRequests - 1);
        return response;
      },
      (error) => {
        setActiveHttpRequests(activeHttpRequests - 1);
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(responseInterceptor);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const unregisterRequestInterceptor = incrementInterceptor();
    const unregisterResponseInterceptor = decrementInterceptor();

    return () => {
      unregisterRequestInterceptor();
      unregisterResponseInterceptor();
    };
  }, [incrementInterceptor, decrementInterceptor]);

  return activeHttpRequests;
}
