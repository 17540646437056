import React from "react";

import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

SafeRoute.propTypes = {
  redirect: PropTypes.string,
  redirectIf: PropTypes.bool,
  children: PropTypes.node,
};

export default function SafeRoute({ redirect, redirectIf, children }) {
  if (typeof redirectIf === "undefined" || redirectIf === null) {
    return <></>;
  }

  return redirectIf ? <Navigate to={redirect} replace={true} /> : children;
}
