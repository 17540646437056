import React, {useEffect} from "react";

import { createBrowserHistory } from "history";
import PropTypes from "prop-types";

import useMetaMask from "../../hooks/useMetaMask";

import ActiveWalletContext from "./ActiveWallet.context";




ActiveWallet.propTypes = {
  children: PropTypes.node,
  props:PropTypes.object,
  path:PropTypes.object
};



function ActiveWallet({ children, props}) {
  const [wallet, network, balance] = useMetaMask();
  const history = createBrowserHistory();

  const contextValue = {
    wallet: wallet,
    network: network,
    balance: balance,
  };
  useEffect(() => {
    if(props.path!== '')
    {
      console.log(props)
      console.log(props.path)
      history.push(props.path)
    }

  }, []);
  return (
    <ActiveWalletContext.Provider value={contextValue}>
      {children}
    </ActiveWalletContext.Provider>
  );
}

export default ActiveWallet;
