import { useState } from "react";

import { useNavigate } from "react-router-dom";

const useBetSummaryConditionals = (index, jury, wallet, creator, opponent, opponentName, status, bet_id) => {

    const navigate = useNavigate()

    const [isStandard, setisStandard] = useState(false);
    const [isJury, setisJury] = useState(false);
    const [isCreator, setisCreator] = useState(false);
    const [buttonStatus, setButtonStatus] = useState("disabled");

    const StandardBetHandler = () => {
        if (index !== undefined) {
            setisStandard(true);
        }
    };

    const checkJury = () => {
        if (jury === wallet) {
            setisJury(true);
        } else if (
            (creator === wallet &&
                isStandard === false &&
                (jury === "pending" || opponent === "pending")) ||
            (creator === wallet && isStandard === true && opponent === "pending")
        ) {
            setisCreator(true);
        } else {
            setisJury(false);
            setisCreator(false);
        }
    };

    const checkOpponent = () => {
        if (opponentName === "") {
            setButtonStatus("disabled");
        } else {
            setButtonStatus("");
        }
    };

    const checkClosed = () => {
        if (status === "closed") {
            navigate(`/bet_result/${bet_id}`, { state: { bet: bet_id } })
        }
    }

    return {
        isJury,
        isCreator,
        isStandard,
        buttonStatus,
        StandardBetHandler,
        checkJury,
        checkOpponent,
        checkClosed,
    }
}

export default useBetSummaryConditionals;
