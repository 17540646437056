import React, { useContext } from "react";

import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";

import SafeRoute from "./components/Route/SafeRoute";
import Loading from "./components/UI/Loading";
import NavaBar from "./components/UI/Navbars";
import ActiveWallet from "./components/Wallet/ActiveWallet";
import ActiveWalletContext from "./components/Wallet/ActiveWallet.context";
import CheckWallet from "./components/Wallet/CheckWallet";
import InstallWallet from "./components/Wallet/InstallWallet"
import BetAccept from "./pages/BetAccept";
import BetResult from "./pages/BetResult";
import BetSummary from "./pages/BetSummary";
import CreateStandardBet from "./pages/CreateStandardBet";
import BetForm from "./pages/JuryBetForm";
import JuryBetShare from "./pages/JuryBetShare";
import MyOpenBets from "./pages/MyOpenBets";
import NftPage from "./pages/NftPage";
import NotFound from "./pages/NotFound";
import StandardBetShare from "./pages/StandardBetShare";
// import EthereumClient from "./utils/EthereumClient";
import HttpClient from "./utils/HttpClient";



import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/global.css";
import "./assets/css/App.scss";

HttpClient.setDefaultHeaders({
  "Content-Type": "application/json",
  // "x-api-key": process.env.REACT_APP_API_KEY,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const route2 = window['paths'];

function App() {

  const { wallet, network } = useContext(ActiveWalletContext);
console.log(window.ethereum)
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/request-wallet"
          element={
            <SafeRoute redirectIf={wallet?.length > 0 && network === "Mumbai"} redirect="/">
              <div className="container betcontainercheck text-center col-sm-6 ">
                <div className="col-12 mt-2">
                  Login with Metamask to start playing!
                </div>
                <CheckWallet />
              </div>

            </SafeRoute>
            
          }
        />
        <Route
          path="/"
          element={
            <SafeRoute
              redirectIf={wallet?.length === 0 || network === undefined || window.ethereum === undefined}
              redirect="/request-wallet"
            >

              <Outlet />
            </SafeRoute>
            
          }
        >
          {/* TODO: Add page for home vs mybets? */}
          <Route path="/" element={<MyOpenBets />} />
          <Route path="bet-create" element={<BetForm />} />
          <Route path="bet-create/standard" element={<CreateStandardBet />} />
          <Route path="bet-share/:bet_id" element={<JuryBetShare />} />
          <Route path="my-open-bets/:betid" element={<BetSummary />} />
          <Route path="bet_jury/*" element={<BetAccept />} />
          <Route path="bet_opponent/*" element={<BetAccept />} />
          <Route path="bet_result/:betid" element={<BetResult />} />
          <Route
            path="bet_standard_share/:betid"
            element={<StandardBetShare />}
          />
          <Route path="nft_create/:betid" element={<NftPage />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
root.render(
  <>
    <div className="App">
      {window.ethereum && <ActiveWallet props={route2} >
        <NavaBar />
        <App />
      </ActiveWallet>}
      {!window.ethereum && <InstallWallet />}
      <div className="container foot-holder">
      {window.ethereum && <Loading />}
      </div>
    </div>
  </>
);

