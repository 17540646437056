import React, { useState } from "react";

import PropTypes from "prop-types";
import { Popover, Button, Overlay, Form } from "react-bootstrap";

PopLink.propTypes = {
    btn: PropTypes.string,
    link: PropTypes.string,
    data: PropTypes.string,
    ref: PropTypes.string
};

function PopLink({ btn, link, data, ref }) {

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const handleClick_copyurl = (event) => {
        setShow(!show);
        setTarget(event.target);
        navigator.clipboard.writeText(link);
    };

    return (
        <>

            <Button className={`${btn} mt-4 marginbtnstop`} onClick={handleClick_copyurl}>
                Copy URL
            </Button>
            <Overlay
                show={show}
                target={target}
                placement="top"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Header as="h3">
                        URL Copied to clipboard
                    </Popover.Header>
                    <Popover.Body>
                        <strong>Share this URL with {data}: </strong>

                        <Form>
                            <Form.Group controlId="url_jury">
                                <Form.Control
                                    type="email"
                                    placeholder="loading url..."
                                    value={link}
                                />
                                <Form.Text className="text-url" />

                            </Form.Group>
                        </Form>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}

export default PopLink;