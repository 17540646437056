import React, { useState, useEffect, useContext } from "react";

import { Container, Tab, Tabs } from "react-bootstrap";

import BetsList from "../components/Bets/BetsList";
import PopUp from "../components/Bets/ChoseBetTypeModal";
import Loading1 from "../components/UI/Loading1";
import ActiveWalletContext from "../components/Wallet/ActiveWallet.context";
import BetService from "../services/BetService";

const betService = new BetService();

function getSortedDistinctBets(current) {
  let value1 = Object.values(
    (current || []).reduce((acc, value) => {
      acc[value.betid] = value;
      return acc;
    }, {})
  ).sort((a, b) => b.betid - a.betid);
  return value1;
}

function MyOpenBets() {
  const { wallet } = useContext(ActiveWalletContext);

  const [isLoading, setIsLoading] = useState(true);
  const [allBets, setBets] = useState([]);
  const [compleatedBets, setCBets] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    console.log(isLoading);
    betService.getAllBets(wallet).then(getSortedDistinctBets).then(setBets);
    betService.getCompletedBets(wallet).then(getSortedDistinctBets).then(setCBets).then( ()=>{
      setIsLoading(false)});
  }, [wallet]);

  return (
    <>
    <Loading1 loaded={isLoading}>
      <div className="container betcontainer col-9">
        <div className="row">
          <div className="col">
            <div className="clearfix listheader container">
              <h1 className="float-start">My Disputes</h1>
              <PopUp />
            </div>
            <Container className="mt-12">
              <Tabs
                defaultActiveKey="openBets"
                id="uncontrolled-tab-example"
                className="mb-12"
              >
                <Tab eventKey="openBets" title="Open Bets">
                  <BetsList items={allBets} />
                </Tab>
                <Tab eventKey="completedBets" title="Completed Bets">
                  <BetsList items={compleatedBets} />
                </Tab>
                <Tab eventKey="judgeBets" title="Bets where I'm judge">
                  <BetsList items={compleatedBets} />
                </Tab>
              </Tabs>
            </Container>
          </div>
        </div>
      </div>
      </Loading1>
    </>
  );
}
export default MyOpenBets;
