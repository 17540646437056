import React, { useContext, useEffect, useState } from "react";

import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaSignOutAlt } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { FaSuitcase } from "react-icons/fa";

import logo from "../../assets/images/novologo.jpeg";
import { keepTheme } from "../../utils/themes";
// import icnMetamask from "../../assets/images/metamask.svg";
import ActiveWalletContext from "../Wallet/ActiveWallet.context";


import PopUpSuccess from "./SuccessModal";
import Toggle from "./Toggle";

// import CheckWallet from "../Wallet/CheckWallet"; //

// import Toggle from "./Toggle";
const { ethers } = require("ethers");

export default function Navbars() {
  // const [setModalShow] = React.useState(false);

  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;

  const [balance, setBalance] = useState("0");
  // const [errMsg, setErrMsg] = useState("");
  // const [succ, setSucc] = useState(false)


  let walletfin =
    `${wallet}`.substring(0, 8) +
    "..." +
    `${wallet}`.substring(`${wallet}`.length - 7);

  const address = "0xd68935f3cbAE00d08CCB2Ec3bD1083a2C36f0382";

  // const getTokensHandler = () => {
  //   const ALCHEMY_API_KEY = "0Nkxqp8dXoDk3SnqgcG1HPGHHm7enZhs";
  //   const providerBet = new ethers.providers.JsonRpcProvider(
  //     `https://eth-rinkeby.alchemyapi.io/v2/${ALCHEMY_API_KEY}`
  //   );
  //   const abi = [
  //     "function mint(address to, uint256 amount) public onlyOwner returns (bool success)",
  //   ];
  //   const privateKey =
  //     "a344561e2f74bbc5ceb89e94e69630311ab2db9cd4a900baf207bd5428b7ec39";
  //   const signersbf = new ethers.Wallet(privateKey, providerBet);

  //   const tokenContract = new ethers.Contract(address, abi, signersbf);

  //   try{
  //   const mint = async () => {
  //     // const balance = await provider.getBalance(wallet)
  //     // console.log(ethers.utils.formatEther(balance))

  //     const tokenWithWallet = tokenContract.connect(signersbf);

  //     const tokenAmount = 5;

  //     const tx = await tokenWithWallet.mint(
  //       wallet,
  //       ethers.utils.parseEther(tokenAmount.toString())
  //     );

  //     console.log(tx);

  //   };
  //   mint();
  //   setSucc(true);
  //  }catch (error) {
  //   console.log(error.message)
  //   setErrMsg(error.message)}
  // };

  const importTokenHandler = async () => {
    const tokenSymbol = "USDX";
    const tokenDecimals = 18;
    const tokenImage =
      "https://www.pngall.com/wp-content/uploads/10/USD-Coin-Logo-PNG-Image.png";

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: address, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ChangeAccountHandler = async () => {
    await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    console.log(wallet);
  };

  const getBalance = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const abi = [
      "function balanceOf(address account) public view returns (uint256)",
    ];

    const contract = new ethers.Contract(address, abi, provider);

    const newBalance = await contract.balanceOf(wallet);

    const smallerBalance = newBalance.mod(1e14);

    setBalance(ethers.utils.formatEther(newBalance.sub(smallerBalance)));
  };

  useEffect(() => {
    keepTheme();
  });
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="navbar-light rounded berrybar"
      variant="light"
    >
      <Container>
        <Navbar.Brand href="/" id="berrybrand">
          <img
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="webet logo"
          />
          <span>XCROW</span>
        </Navbar.Brand>
        {/* Desktop nav */}
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {/* Menu when needed */}
          {/*       <Nav className="me-auto " id="mmenu">
            <Nav.Link href="/home" className="float-end navbar-nav pull-right">Home</Nav.Link>
            <Nav.Link href="/my-bets">Bets</Nav.Link>
            <Nav.Link href="/About" className="About">About</Nav.Link>
            <Nav.Link href="https://mainwebsite.com">Contact</Nav.Link> 
          </Nav>
            */}
          <Nav id="nearuser">
            <div id="tempButton">
              {wallet !== "" && (

                <PopUpSuccess className="justify-content-start" />

              )}
            </div>
            <div id="tempButton">
              {wallet !== "" && (
                <Nav.Link
                  onClick={importTokenHandler}
                  className="justify-content-start"
                >
                  <FaPlusCircle /> Import USDX Token
                </Nav.Link>
              )}
            </div>

            {wallet !== "" && (
              <>
                <NavDropdown
                  title={walletfin}
                  id="collasible-nav-dropdown"
                  className="userBtn"
                  onClick={getBalance}
                >
                  {/* <NavDropdown.Item  onClick={() => setModalShow(true)}><LogoBanana/>  Buy Bananas 123 64 321 313</NavDropdown.Item> */}
                  {/* <NavDropdown.Divider /> */}
                  <div className="statsBox">
                    {/* <span>{wallet}</span> */}
                    <div>
                      USDX (Stablecoin):{" "}
                      <span className="priceValue">{balance}</span>
                    </div>
                    <div>
                      XCRW: <span className="priceValue">0</span>
                    </div>
                  </div>
                  <NavDropdown.Item href="/my-open-bets">
                    <FaBriefcase /> My Bets
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/my-open-bets" className="disabled">
                    <FaSuitcase /> My NFT Collectables
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/acount" className="disabled">
                    <FaUserCircle /> Account (Level 3)
                  </NavDropdown.Item>
                  <Toggle />
                  <NavDropdown.Item href="http://docs.webetnetwork.com/">
                    <FaQuestionCircle /> Help
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={ChangeAccountHandler}>
                    <FaSignOutAlt /> Change Account{" "}
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
