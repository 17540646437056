import React, { useEffect, useState } from "react";

import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";

import { setTheme } from "../../utils/themes";

function Toggle() {
  // false = dark mode because of the way I wrote the CSS
  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = useState(false);
  // the opposite, for screenreaders
  // eslint-disable-next-line no-unused-vars
  const [ariaActive, setAriaActive] = useState(true);
  let theme = localStorage.getItem("theme");

  const changeThemeAndToggle = () => {
    if (localStorage.getItem("theme") === "theme-dark") {
      setTheme("theme-light");
      setActive(true);
      setAriaActive(false);
      setCount(0);
    } else {
      setTheme("theme-dark");
      setActive(false);
      setAriaActive(true);
      setCount(1);
    }
  };

  const handleOnClick = () => {
    changeThemeAndToggle();
  };

  useEffect(() => {
    if (localStorage.getItem("theme") === "theme-dark") {
      setActive(false);
      setAriaActive(true);
    } else if (localStorage.getItem("theme") === "theme-light") {
      setActive(true);
      setAriaActive(false);
    }
  }, [theme]);

  const [count, setCount] = useState(1);
  useEffect(() => {
    if (count > 1) {
      // console.log("Count is more that 5");
    } else {
      // console.log("Count is less that 5");
    }
  }, [count]);
  // eslint-disable-next-line no-unused-vars
  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <div className="container--toggle dropdown-item" onClick={handleOnClick}>
      {count === 0 ? <FaToggleOff /> : <FaToggleOn />}
      <span className="toggle--label-background">
        Dark Mode: {count === 0 ? <span>OFF</span> : <span>ON</span>}
      </span>
    </div>
  );
}

export default Toggle;
