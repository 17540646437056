import React from "react";

import PropTypes from "prop-types";

import "./BetDate.scss";

BetDate.propTypes = {
  date: PropTypes.instanceOf(Date),
};

function BetDate({ date }) {
  const month = date.toLocaleString("en-US", { month: "long" });
  const hour = date.toLocaleString("en-US", { hour: "2-digit", minute: "2-digit" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const year = date.getFullYear();
  const timeZone = date.toLocaleString("en-US", { timeZoneName: "short" }).slice(-5)

  return (
    // <div className="bet-datex">
    //   <div className="bet-date__monthx">{month}</div>
    //   <div className="bet-date__yearx">{year}</div>
    //   <div className="bet-date__dayx">{day}</div>
    // </div>
    <div>
      {day}/{month}/{year} {hour} {timeZone}
    </div>
  );
}

export default BetDate;
