import React, { useCallback, useEffect, useState, useContext } from "react";

import axios from "axios";
import { Card, Container, Button, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import NftModal from "../components/Bets/NftModal";
import ActiveWalletContext from "../components/Wallet/ActiveWallet.context";


function BetResult() {
  const location = useLocation();
  const bet_id = location.state.bet;

  console.log(bet_id);

  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;

  const navigate = useNavigate();

  const url = "https://api.xcrow.net/bets/" + bet_id + "/?status=closed";
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [participant_type, setParticipant_type] = useState("");
  const [creator, setCreator] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [opponent, setOpponent] = useState("pending");
  const [opponentName, setOpponentName] = useState("");
  const [jury, setJury] = useState("pending");
  const [juryName, setJuryName] = useState("");
  const [winners, setWinners] = useState("pending");
  const [pos, setPos] = useState("");
  const [isStandard, setisStandard] = useState(false);
  const [index, setIndex] = useState();

  const getData = useCallback(async () => {
    await axios
      .get(url, headers)
      .then((bets) => {
        console.log(bets.data.value[0]);
        setIndex(bets.data.value[0].index_bet_a);
        if (index !== undefined) {
          setisStandard(true);
        }
        setAmount(bets.data.value[0].creator.value);
        setDescription(bets.data.value[0].description);
        setDate(bets.data.value[0].bet_duration);
        setParticipant_type(bets.data.value[0].participant_type);
        setCreator(bets.data.value[0].creator.wallet);
        setCreatorName(bets.data.value[0].creator.name);
        if (isStandard === false) {
          setJury(Object.keys(bets.data.value[0].jury)[0]);
          setJuryName(Object.values(bets.data.value[0].jury)[0].name);
          setOpponent(Object.keys(bets.data.value[0].opposition)[0]);
          setOpponentName(Object.values(bets.data.value[0].opposition)[0].name);
          setWinners(Object.keys(bets.data.value[0].winners)[0]);
        } else {
          setOpponent(Object.keys(bets.data.value[0].opposition)[0]);
          setOpponentName(Object.values(bets.data.value[0].opposition)[0].name);
          setWinners(Object.keys(bets.data.value[0].winners)[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [headers, url]);

  console.log(winners);

  const position = () => {
    if (wallet === winners) {
      setPos("Congrats, you won!!");
    } else {
      setPos("I'm sorry you loss, better luck next time");
    }
  };

  const CloseHandler = (event) => {
    navigate(`/`);
  };

  const NFTHandler = (event) => {
    navigate(`/nft_create/${bet_id}`, { state: { bet: bet_id } });
  };

  useEffect(() => {
    getData();
    position();
  }, [getData, position]);

  // const info = {
  //   buttonStatus: buttonStatus,
  //   creatorName: creatorName,
  //   creator: creator,
  //   opponentName: opponentName,
  //   opponent: opponent
  // }

  const dDate = new Date(date)

  const month = dDate.toLocaleString("en-US", { month: "long" });
  const hour = dDate.toLocaleString("en-US", { hour: "2-digit", minute: "2-digit" });
  const day = dDate.toLocaleString("en-US", { day: "2-digit" });
  const year = dDate.getFullYear();
  const timeZone = dDate.toLocaleString("en-US", { timeZoneName: "short" }).slice(-5)

  return (
    <>
      <Container className="mt-5 betcontainer">
        <Card className="text-center black-background">
          <Card.Header>Bet Status: {pos}</Card.Header>
          <Card.Body>
            <Card.Title>Description:{description} </Card.Title>

            <Card.Text>
              You are: {participant_type}
              <br />
              Amount : {amount}
            </Card.Text>

            <Row>
              <Col>
                <Button className="me-4" variant="primary" disabled>
                  Creator:
                </Button>
                {creatorName}/{creator}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button className="me-4" variant="primary" disabled>
                  Opponent:
                </Button>
                {opponentName}/{opponent}
              </Col>
            </Row>
            <br />
            {!isStandard && (
              <Row>
                <Col>
                  <Button className="me-4" variant="primary" disabled>
                    Jury:
                  </Button>
                  {juryName}/{jury}
                </Col>
              </Row>
            )}

            <NftModal betid={bet_id} />

            {console.log(NFTHandler)}

          </Card.Body>

          <Button variant="info" size="xs" className="smallerbtn" onClick={CloseHandler}>
            Close
          </Button>
          <Card.Footer>
            Finish Date: {day}/{month}/{year} {hour} {timeZone}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}
export default BetResult;
