import { useState } from "react";

import { useNavigate } from "react-router-dom";

import sbfArtifact from "../abis/StandardBetFactoryKeeper.json";
import PutAcceptBet from "../services/PutAcceptBet";

const { ethers } = require("ethers");

const useStandardBetCreate = (
    bet_id,
    wallet,
    participant_bet,
    isStandard,
    contractAddress,
    amount,
    date,
    creator,
    participant_type1,
    participant_group1,
    username,
    oracle,
    comparison,
    prediction,
    validity,
) => {

    const navigate = useNavigate();

    const [dataModalStandard, setDataModal] = useState("")
    const [modalShowStandard, setModalShow] = useState(false);
    const [errMsgStandard, setErrMsgStandard] = useState("")

    const putData = PutAcceptBet(bet_id, wallet, participant_type1, participant_group1, username)

    if (participant_bet === "bet_opponent" && isStandard === true) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
        const providerBet = new ethers.providers.JsonRpcProvider(
            `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_API_KEY}`
        );
        const address = "0xd68935f3cbAE00d08CCB2Ec3bD1083a2C36f0382";
        const abi = [
            "function approve(address _spender, uint256 _value) public returns (bool success)",
            "function allowance(address owner, address spender) external view returns (uint256)",
        ];

        const upkeepAddress = "0x4040FCaa3abBE42609D90c1214288f574a1E5F7D";
        const abiUpkeep = [
            "function registerAndPredictID (string memory name,bytes calldata encryptedEmail,address upkeepContract,uint32 gasLimit,address adminAddress,bytes calldata checkData,uint96 amount,uint8 source) public returns (bool success)",
        ];
        //TEST WALLET KEY
        const addressBet = contractAddress.sbfactory;
        const abiBet = sbfArtifact.abi;
        const privateKey = process.env.REACT_APP_MUMBAI_PRIVATE_KEY;

        const signersbf = new ethers.Wallet(privateKey, providerBet);
        const sbfContract = new ethers.Contract(addressBet, abiBet, signersbf);

        const signer = provider.getSigner(0);
        const tokenContract = new ethers.Contract(address, abi, signer);

        const upkeepSigner = new ethers.Wallet(privateKey, providerBet);
        const upkeepContract = new ethers.Contract(
            upkeepAddress,
            abiUpkeep,
            upkeepSigner
        );

        // const tokenContractAllowance = new ethers.Contract(address, abi, providerBet);

        const approve = async () => {
            // const balance = await provider.getBalance(wallet)
            // console.log(ethers.utils.formatEther(balance))
            try {
                const tokenWithWallet = tokenContract.connect(signer);

                await tokenWithWallet.approve(
                    addressBet,
                    ethers.utils.parseEther(amount.toString())
                )

                console.log("approve");
                return true
            }
            catch (error) {
                console.log(error)
                setModalShow(false)
                setErrMsgStandard(error.message)
            }
        };

        const createSB = async () => {
            const sbfcontractWithWallet = sbfContract.connect(signersbf);
            await sbfcontractWithWallet.createBet(
                oracle,
                bet_id,
                comparison,
                prediction,
                ethers.utils.parseEther(amount.toString()),
                validity,
                date / 1000,
                creator,
                wallet
            );
            return true
        };

        const addUpkeep = async () => {
            try {
                const upkeepContractWithWallet = upkeepContract.connect(upkeepSigner);
                const sbfcontractWithWallet = sbfContract.connect(signersbf);
                sbfcontractWithWallet.betAddress(bet_id).then(async (resp) => {
                    await upkeepContractWithWallet.registerAndPredictID(
                        bet_id.toString(),
                        "0x",
                        resp,
                        180000,
                        "0x694f022583C7E93F036c2C53d547fda163580edC",
                        "0x",
                        "5000000000000000000",
                        "0"
                    );

                })
                return true
            } catch (error) {
                console.log(error.message)
                setModalShow(false)
                setErrMsgStandard(error.message)
            }
        };

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const chainCreateStandardBet = async () => {
            try {
                setModalShow(true)
                setDataModal("1/3 Waiting for the approval..")
                await approve().then(async (response) => {
                    await delay(20000)
                    if (response === true) {
                        await createSB().then(async (response) => {
                            console.log(response)
                            setDataModal("2/3 Bet being created")
                            await delay(20000)
                            if (response === true) {
                                await addUpkeep().then(async (response) => {
                                    console.log(response)
                                    setDataModal("3/3 Seeting the keeper to be checking the result")
                                    await delay(20000)
                                    if (response === true) {
                                        await putData().then(() => {
                                            setModalShow(false)
                                            navigate(`/my-open-bets/${bet_id}`, { state: { bet: bet_id } })
                                        })
                                    }
                                });
                            }
                        });
                    }
                })
                // })
            } catch (error) {
                console.log(error)
                setModalShow(false)
            }
        };
        return {
            modalShowStandard,
            dataModalStandard,
            errMsgStandard,
            chainCreateStandardBet
        }
    }
    else {
        const chainCreateStandardBet = () => {
            return;
        }

        return {
            modalShowStandard,
            dataModalStandard,
            errMsgStandard,
            chainCreateStandardBet
        }
    }
}

export default useStandardBetCreate;