function FilterChainlinkData(adresses, adressType, network, pairType) {
    const networks = adresses[adressType].networks;
    const finalNetwork = networks.filter((ntwrk) =>
        ntwrk.name.toLowerCase().includes(network)
    );
    const mapProxies = finalNetwork[0].proxies;
    // setBetlist(mapProxies.filter(prs=>prs.pair.toLowerCase().includes(pairType)));
    return mapProxies.filter((prs) => prs.pair.toLowerCase().includes(pairType));
}

export default FilterChainlinkData;