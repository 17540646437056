export default class EthereumClient {
  static ethereum = window.ethereum;
  static activeAccountRequest = null;

  static listeners = [];

  /**
   *
   * @param {string} eventName name of the event
   * @param {object} payload payload to be sent to listeners
   */
  static async forceListener(eventName, payload) {
    const result = await Promise.resolve(payload);

    EthereumClient.listeners
      .filter(({ event }) => event === eventName)
      .forEach(({ callback }) => {
        callback(result);
      });
  }

  /**
   *
   * @param {*} event event to listen
   * @param {*} callback calback function to be triggered
   * @returns {function} unregister function
   */
  static addListener(event, callback) {
    EthereumClient.listeners.push({ event, callback });
    EthereumClient.ethereum.on(event, callback);
    return () => {
      EthereumClient.ethereum.removeListener(event, callback);
    };
  }

  /**
   *
   * @returns {number} id of current network
   */
  static async getChainId() {
    return EthereumClient.ethereum.request({ method: "net_version" });
  }

  /**
   *
   * @returns {string[]} list of available accounts
   */
  static async getAccounts() {
    return EthereumClient.ethereum.request({ method: "eth_accounts" });
  }

  static async requestAccount() {
    EthereumClient.activeAccountRequest = EthereumClient.activeAccountRequest
      ? EthereumClient.activeAccountRequest
      : EthereumClient.ethereum.request({ method: "eth_requestAccounts" });

    const request = EthereumClient.activeAccountRequest;

    await request;
    EthereumClient.activeAccountRequest = null;
    EthereumClient.forceListener(
      "accountsChanged",
      EthereumClient.getAccounts()
    );
    EthereumClient.forceListener("chainChanged", EthereumClient.getChainId());

    return request;
  }

  /**
   *
   * @param {string} accountId id of the account
   * @returns balance for the current account
   */
  static async getAccountBalance(accountId) {
    return EthereumClient.ethereum
      .request({
        method: "eth_getBalance",
        params: [accountId, "latest"],
      })
      .then((value) => {
        return parseInt(value, 16);
      });
  }

  /** Invocked when a change to account occurs
   * @callback AccChanged
   * @param {string[]} accounts of user accounts
   */
  /** Add listener to account changes
   * @param {AccChanged} callback listener for account changes
   * @returns {function} listener clean up
   */
  static accountsChangedListener(callback) {
    return this.addListener("accountsChanged", callback);
  }

  /** Invocked when a change to chain occurs
   * @callback AccChanged
   * @param {string} chainId new chain ID
   */
  /** Add listener to chain changes
   * @param {AccChanged} callback listener for chain changes
   * @returns {function} listener clean up
   */
  static chainChangedListener(callback) {
    return this.addListener("chainChanged", callback);
  }
}
