import React from "react"

import { Button, Container } from "react-bootstrap"

export default function InstallWallet() {


    return(
        <>
        <Container className="installWallet">
            <div>
            Noticed you do not have a wallet in your browser, please install one or click the button below:
            </div>
        <Button href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
            Install Metamask!
        </Button>
        </Container>
</>
    )
}