import React, { useEffect, useContext } from "react";

import { Card, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import JudgeVeridictModal from "../components/Bets/JudgeVeridictModal";
import Loading1 from "../components/UI/Loading1";
import ActiveWalletContext from "../components/Wallet/ActiveWallet.context";
import useBetSummaryConditionals from "../hooks/useBetSummaryConditionals";
import useCheckWinner from "../hooks/useCheckWinner";
import useFormatedData from "../hooks/useFormatedDate";
import useGetById from "../hooks/useGetById";

import JuryBetShare from "./JuryBetShare";
import StandardBetShare from "./StandardBetShare";

function BetSummary() {
  const location = useLocation();
  const bet_id = location.state.bet;

  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;

  const { amount,
    description,
    date,
    opponent,
    jury,
    opponentName,
    juryName,
    status,
    participant_type,
    creator,
    creatorName,
    index,
    winners,
    url,
    isLoading,
    getData } = useGetById(bet_id)

  const { getBetAddress } = useCheckWinner(bet_id, opponent, creator, opponentName, creatorName, wallet, amount)
  const { finalDate } = useFormatedData(date)
  const {
    isJury,
    isCreator,
    isStandard,
    buttonStatus,
    StandardBetHandler,
    checkJury,
    checkOpponent,
    checkClosed
  } = useBetSummaryConditionals(index, jury, wallet, creator, opponent, opponentName, status, bet_id)

  const getWinnerFromChain = () => {
    StandardBetHandler();
    if (!isStandard || opponent === "pending") {
      console.log("estou a correr eu")
      console.log(!isStandard)
      console.log(opponent)
    } else {
      getBetAddress()
      console.log("estou a correr")
    }
  }

  useEffect(() => {
    getData("loading")
    console.log(isLoading);
    getWinnerFromChain()
  }, [url]);

  useEffect(() => {
    checkJury();
    checkOpponent();
    StandardBetHandler();
    getWinnerFromChain()
    checkClosed();
  }, [opponent, jury, status, creator, winners, wallet, url]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
      getWinnerFromChain()
    }, 15000);
    return () => clearInterval(interval);
  }, []);


  return (
    <>
    <Loading1 loaded={isLoading}>
      {!isJury && !isCreator && (
        <div className="container betcontainer col-9 betsummary">
          <div className="container  col-10">
            <div className="row">
              <div className="col-sm">Bet Status:  {status}</div>
              <div className="col-sm">You are:  {participant_type}</div>
            </div>

            <div className="row">
              <div className="col-sm">
                Finish Date: {finalDate}
              </div>
              <div className="col-sm">Amount:  {amount} USD</div>
            </div>

            <div className="row mt-4">

              <div>Description:  {description}</div>
            </div>
            <div className="texthelper">
              The dispute is now pending the veridict, good luck
            </div>
            <Container className="inlinebtn text-center">
              <p className="me-4 inlinebtn green-text">
                Creator:
              </p>
              <p className="inlinebtn">{creatorName} / {creator}</p>
            </Container>

            <Container className="inlinebtn text-center">
              <p className="me-4 inlinebtn green-text" >
                Opponent:
              </p>

              <p className="inlinebtn">
                {opponentName}/{opponent}
              </p>
            </Container>
          </div>
          {!isStandard && (
            <Container className="inlinebtn text-center">
              <p className="me-4 inlinebtn green-text" >
                Jury:
              </p>
              <p className="inlinebtn">
                {juryName}/{jury}
              </p>
            </Container>
          )}
        </div>
      )
      }
      {
        isJury && (
          <Container className="mt-5 betcontainer">
            <Card className="text-center black-background">
              <Card.Header>Bet Status: {status}</Card.Header>
              <Card.Body>
                <Card.Title>Description:{description} </Card.Title>

                <Card.Text className="mb-5">
                  Amount : {amount} USD
                  <br />
                  <br />
                  You are: THE JURY!!!!!!!

                </Card.Text>
                <JudgeVeridictModal
                  btn={buttonStatus}
                  cName={creatorName}
                  c={creator}
                  oName={opponentName}
                  o={opponent}
                  desc={description}
                  betid={bet_id}
                  value={amount}
                />
              </Card.Body>
              <Card.Footer className="card-footerborder">
                Finish Date: {finalDate}
              </Card.Footer>
            </Card>
          </Container>
        )
      }
      {isCreator && !isStandard && !isJury && < JuryBetShare />}
      {isCreator && isStandard && <StandardBetShare />}
      </Loading1>
    </>
  );
}

export default BetSummary;
