import { useState, useCallback} from "react";

import axios from "axios";

const useGetQRs = (bet_id) => {

    const [isLoading, setIsLoading] = useState(false)
    const QR_URL = process.env.REACT_APP_QR_URL;
    const webpage = "https://xcrow.net";

    const [linkJury, setLinkJury] = useState("");
    const [linkOpponent1, setOpponent1] = useState("");
    const [qrJury, setqrJury] = useState("");
    const [qrOpponent1, setqrOpponent1] = useState("");
    const [btn, setBtnStatus] = useState("disabled");

    const generateQR = useCallback( async () => {
        const url = QR_URL;
        const data = {
            root_url: webpage,
            Records: [
                {
                    path: webpage + "/bet_jury/?bet=" + bet_id,
                    participant_type: 11,
                },
                {
                    path: webpage + "/bet_opponent/?bet=" + bet_id,
                    participant_type: 22,
                },
            ],
        };
        setIsLoading(true);
        axios
            .post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.REACT_APP_API_KEY,
                },
            })
            .then((response) => {
                setLinkJury(response.data[11].url);
                setOpponent1(response.data[22].url);
                setqrJury(response.data[11].qrcode);
                setqrOpponent1(response.data[22].qrcode);
                setBtnStatus("enabled")
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [bet_id]);

    return {
        isLoading,
        linkJury: linkJury,
        linkOpponent1: linkOpponent1,
        qrJury: qrJury,
        qrOpponent1: qrOpponent1,
        btn,
        generateQR
    };
}

export default useGetQRs;