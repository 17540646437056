import { useEffect, useState } from "react";

import EthereumClient from "../utils/EthereumClient";

export default function useMetaMask() {
  const [account, setAccount] = useState(null);
  const [chain, setChain] = useState(null);
  const [balance, setBalance] = useState(null);

  const networks = {
    80001: "Mumbai",
  };



  useEffect(() => {
    async function updateAccount(accounts) {
      // setChain(await EthereumClient.getChainId());
      setAccount(accounts[0] || "");
      if (!accounts?.length) {
        return;
      }
      setBalance(await EthereumClient.getAccountBalance(accounts[0]));
      // setChain(await EthereumClient.getChainId());//ADDED Pinto to put the chain working
    }

    const accChangeUnregister =
      EthereumClient.accountsChangedListener(updateAccount);
    const chainChangeUnregister = EthereumClient.chainChangedListener(
      (chainId) => chainId && setChain(networks[chainId])
    );


    (async () => {
      await updateAccount(await EthereumClient.getAccounts());
    })();

    return () => {
      accChangeUnregister();
      chainChangeUnregister();
    };
  }, [account, chain]); // eslint-disable-line react-hooks/exhaustive-deps

  return [account, chain, balance];
}
