import React, { useContext } from "react";

import { Button, Col, Form, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import contractAddress from "../abis/contract-address.json";
import AlertDanger from "../components/UI/AlertDanger";
import LoadingModal from "../components/UI/LoadingModal";
import ActiveWalletContext from "../components/Wallet/ActiveWallet.context";
import useApproveFunds from "../hooks/useApproveFunds";
import usePositiveNumbers from "../hooks/usePositiveNumbers";
import DateFormInput from "../services/DateFormInput"
import JuriService from "../services/JuriService";

export default function JuryBetForm() {

  const navigate = useNavigate();

  const navigateURL = `/bet-share/`

  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;

  const addressBet = contractAddress.jbfactory;

  const juriService = new JuriService(wallet);
  const { register, handleSubmit } = useForm();
  const { dataHook, errMsg, modalShow, setDataModal, checkFunds, approveFunds } = useApproveFunds(wallet, addressBet)
  const { betvalor, updateInputValue } = usePositiveNumbers()

  const onSubmit = async (formdata) => {

    const { name, description, value, bet_duration } = formdata;
    const data = {
      creator: {
        name,
        value: Number(value),
        wallet: wallet,
      },
      description,
      bet_duration: new Date(bet_duration).getTime(),
      participant_type: "opponent",
    };

    try {
      const approvedfunds = await checkFunds()
      if (approvedfunds / 10 ** 18 >= value) {
        setDataModal("Funds available, creating your dispute..")
        await juriService.createBet(data).then((response) => {
          setTimeout(() => {
            navigate(`${navigateURL}${response}`, { state: { bet: response } })
          }, "1000")
        })
      } else {
        approveFunds(value).then((response) => {
          if (response === true) {
            juriService.createBet(data).then((response) => {
              setTimeout(() => {
                navigate(`${navigateURL}${response}`, { state: { bet: response } })
              }, "3000")
            })
          }
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <LoadingModal
        show={modalShow}
        data={dataHook}
      />
      <div className="container betcontainer">
        {errMsg !== "" && AlertDanger(errMsg)}
        <div className="row">
          <div className="col">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <FormLabel>Name:</FormLabel>
                <FormControl
                  type="text"
                  placeholder="User name"
                  required
                  {...register("name", { required: true, maxLength: 400 })}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>Description</FormLabel>
                <FormControl
                  type="text"
                  placeholder="I bet that"
                  required
                  as="textarea"
                  rows={3}
                  {...register("description", {
                    required: true,
                    maxLength: 400,
                  })}
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup className="mb-3 " onChange={updateInputValue}>
                    <FormLabel>Amount (USDX):</FormLabel>
                    <FormControl
                      type="number"
                      placeholder="Amount you want to bet"
                      required
                      value={betvalor}
                      {...register("value", { required: true })}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Bet Limit Date</FormLabel>
                    <FormControl className="changeColor"
                      type="datetime-local"
                      required
                      min={DateFormInput()}
                      {...register("bet_duration", { required: true })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Button className="color-blue alignButton" type="submit">Submit</Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
