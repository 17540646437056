import React from "react";


import PropTypes from "prop-types";

Loading1.propTypes = {
    loaded:PropTypes.bool,
    children: PropTypes.node,
  };


export default function Loading1({loaded, children}) {
    
    
  return (
    <>
    {loaded ===true &&(
    <div className="loader-container">
      <div className="spinner"/>
    </div>
    )}
    {loaded===false && children}

    </>
  );
}
