import React, { useContext, useState } from "react";

import axios from "axios";
import PropTypes from "prop-types";
import { Button, Col, Row, Modal, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "../../assets/css/popup.css";
import contractAddress from "../../abis/contract-address.json";
import jbfArtifact from "../../abis/JuriBetFactory.json";
import AlertDanger from "../UI/AlertDanger";
import ActiveWalletContext from "../Wallet/ActiveWallet.context";


const { ethers } = require("ethers");

JudgeVeridictModal.propTypes = {
  btn: PropTypes.string,
  cName: PropTypes.string,
  c: PropTypes.string,
  oName: PropTypes.string,
  o: PropTypes.string,
  desc: PropTypes.string,
  betid: PropTypes.string,
  value: PropTypes.number,
};

export default function JudgeVeridictModal({
  btn,
  cName,
  c,
  oName,
  o,
  desc,
  betid,
  value,
}) {
  const navigate = useNavigate();

  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;

  console.log(wallet);

  const [errMsg, setErrMsg] = useState("")
  const [show, setShow] = useState(false);
  const [winner, setWinner] = useState("");
  const [winnerName, setWinnerName] = useState("");

  const putUrl =
    "https://api.xcrow.net/bets/" + betid + "/verdict/?wallet=" + wallet;

  const data = {
    verdict: "juri said that " + winnerName + " won",
    verdict_type: "bool",
    winners: {
      [winner]: { name: winnerName, value: value.toString() },
    },
  };
  const TypeChecker = () => {
    if (c === wallet || o === wallet) {
      return navigate(`/bet_result/${betid}`, { state: { bet: betid } });
    } else return navigate("/my-open-bets/");
  };

  const handleClose = () => setShow(false);

  const handleShow1 = () => {
    setShow(true);
    setWinner(c);
    setWinnerName(cName);
  };

  const handleShow2 = () => {
    setShow(true);
    setWinner(o);
    setWinnerName(oName);
  };

  const putData = () => {
    axios.put(putUrl, data, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    });
    console.log(data);
  };
  /////////////blockchain side //////////////
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const instanceABI = [
    "function settle(address winner) public returns (bool success)",
  ];

  const addressFact = contractAddress.jbfactory;
  const abiBetFact = jbfArtifact.abi;
  const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
  const providerBet = new ethers.providers.JsonRpcProvider(
    `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_API_KEY}`
  );

  const privateKey = process.env.REACT_APP_MUMBAI_PRIVATE_KEY;

  const signerjbf = new ethers.Wallet(privateKey, providerBet);
  const jbfContract = new ethers.Contract(addressFact, abiBetFact, signerjbf);

  const signer = provider.getSigner(0);

  const getAddress = async () => {
    const jbfcontractWithWallet = jbfContract.connect(signerjbf);
    const addressInstance = await jbfcontractWithWallet.betAddress(betid);
    console.log(addressInstance);

    const settleContract = new ethers.Contract(
      addressInstance,
      instanceABI,
      signer
    );
    const settleContractWithWallet = settleContract.connect(signer);
    const settleInstance = await settleContractWithWallet.settle(winner);

    console.log(settleInstance);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onClickHandler = async () => {
    try {
      await getAddress();
      await delay(3000);
      putData();
      setTimeout(() => {
        TypeChecker();
      }, 3000);
    } catch (error) {
      console.log(error.message)
      setErrMsg(error.message.toString())
    }
  };

  return (
    <>
      <Row>
        {errMsg !== "" && AlertDanger(errMsg)}
        <h2>Whats the veridict?? Who won?</h2>
        <Col className="mt-3">
          <p>Creator</p>
          <Button
            className={`me-4 ${btn}`}
            variant="primary"
            onClick={handleShow1}
          >
            {cName} / {c}
          </Button>
        </Col>
        <Col className="mt-3">
          <p>Opponent</p>
          <Button
            className={`me-4 ${btn}`}
            variant="primary"
            onClick={handleShow2}
          >
            {oName} / {o}
          </Button>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} className="popbox jurymodal">
        {/* <Modal.Dialog show={show} onHide={handleClose} className="popbox no-shadow"> */}
        <Modal.Header closeButton>
          <Modal.Title>Final Veridict</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{desc}</p>
          <b>Winner Chosen is: {winnerName}</b>
          <br />
          <br />
          <b>{winner}</b>
        </Modal.Body>
        <Alert className="alert alert-danger smallerh2">
          <h2>Warning</h2>
          <p>This is a final decision please make sure you know that!</p>
        </Alert>
        <Modal.Footer className="modalfooterborder">
          <Button variant="info" size="xs" onClick={handleClose}>
            Close
          </Button>
          <Button variant="info" size="xs" onClick={onClickHandler}>
            Confirm Veridict
          </Button>
        </Modal.Footer>
        {/* </Modal.Dialog> */}
      </Modal>
    </>
  );
}
