import React, { useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsPatchCheck } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import { BsGraphUp } from "react-icons/bs";
import { useNavigate } from "react-router-dom";



NftModal.propTypes = {
  betid: PropTypes.number

};


export default function NftModal({ betid }) {

  const [show, setShow] = useState(false);
  const [isLoading] = useState(false);
  console.log(betid);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const NFTHandler = (event) => {
    navigate(`/nft_create/${betid}`, { state: { bet: betid } });
  };

  return (
    <>
      <div className="text-middle float-middle">
        <Button
          variant="primary btn-lg xcrowcreate"
          disabled={isLoading}
          onClick={!isLoading ? handleShow : null}
        >
          <BsPatchCheck />
          {isLoading ? "Loading…" : "Get NFT"}
        </Button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="popbox"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Choose the type of Xcrow to create:</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="cardx mb-4 box-shadow">
                  <div className="cardx-header text-center">
                    <h4 className="my-0 font-weight-normal">
                      {" "}
                      <BsPeople /> Free Certificate
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      A free certificate to share with your friends or on social media.
                    </p>
                    <div className="text-center">
                      <Button variant="info" type="xs" onClick={NFTHandler}>
                        Free Certificate
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                {" "}
                <div className="cardx mb-4 box-shadow">
                  <div className="cardx-header text-center">
                    <h4 className="my-0 font-weight-normal">
                      <BsGraphUp /> Noob NFT
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      A certified NFT that you can share with your friends or on social media
                    </p>
                    <div className="text-center">
                      <Button variant="info" type="xs" onClick={NFTHandler}>
                        NFT Certificate
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
