import axios from "axios";

export default class JuriService {
  static API_JURI = process.env.REACT_APP_API_JURI;

  constructor(wallet) {
    this.axios = axios.create({
     baseURL: JuriService.API_JURI + wallet,
    });
    this.axios.defaults.headers.common = {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY,
    };
    this.axios.interceptors = axios.interceptors;
  }

  /**
   *
   *
   * @param {object} data
   * @param {object} data.creator
   * @param {string} data.creator.name
   * @param {string} data.creator.wallet
   * @param {number} data.creator.value
   * @param {string} data.description
   * @param {Date} data.bet_duration
   * @param {string} data.participant_type
   * @returns {Promise}
   * 
   * 
   */
  async createBet(data, url) {
    const response = await this.axios.post("", data);
    return response?.data?.value;
  }
}
