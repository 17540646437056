import React, { useEffect, useState, useContext } from "react";

import {
  Card,
  Container,
  Button,
  Col,
  Row,
  Form,
  FormLabel,
  FormControl,
  FormGroup,
} from "react-bootstrap";

import contractAddress from "../abis/contract-address.json";
import AlertDanger from "../components/UI/AlertDanger";
import Loading1 from "../components/UI/Loading1";
import LoadingModal from "../components/UI/LoadingModal";
import ActiveWalletContext from "../components/Wallet/ActiveWallet.context";
import useFormatedData from "../hooks/useFormatedDate";
import useGetById from "../hooks/useGetById";
import useJuryBetCreate from "../hooks/useJuryBetCreate";
import useStandardBetCreate from "../hooks/useStandardBetCreate";
import GetURL from "../services/GetURL";
// import PutAcceptBet from "../services/PutAcceptBet";


function BetAccept() {
  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;
  //url gets
  const { participant_bet, bet_id } = GetURL();
  let participant_type1;
  let participant_group1;
  if (participant_bet === "bet_jury") {
    participant_type1 = "judge";
    participant_group1 = "jury";
  } else {
    participant_type1 = "opponent";
    participant_group1 = "opposition";
  }
  //states
  // const [errMsg, setErrMsg] = useState("")
  const [username, setUsername] = useState("");
  const [btn, setBtnStatus] = useState("");
  const [isStandard, setisStandard] = useState(false);

  const { amount,
    description,
    date,
    opponent,
    jury,
    opponentName,
    juryName,
    creator,
    creatorName,
    index,
    oracle,
    comparison,
    prediction,
    validity,
    isLoading,
    getData } = useGetById(bet_id)

  const { errMsgJury, modalShowJury, dataModalJury, chainCreateJuryBet } = useJuryBetCreate(
    bet_id,
    wallet,
    participant_bet,
    isStandard,
    contractAddress,
    amount,
    description,
    date,
    creator,
    jury,
    participant_type1,
    participant_group1,
    username,
  )

  const { errMsgStandard, modalShowStandard, dataModalStandard, chainCreateStandardBet } = useStandardBetCreate(
    bet_id,
    wallet,
    participant_bet,
    isStandard,
    contractAddress,
    amount,
    date,
    creator,
    participant_type1,
    participant_group1,
    username,
    oracle,
    comparison,
    prediction,
    validity,
  )



  //clean date
  const { finalDate } = useFormatedData(date)

  const btnfunc = () => {
    if (
      jury === "pending" &&
      participant_bet === "bet_opponent" &&
      isStandard === false
    ) {
      setBtnStatus("disabled");
    } else {
      setBtnStatus("");
    }
  };

  const StandardBetHandler = () => {
    if (index !== undefined) {
      setisStandard(true);
      console.log(isStandard);
      console.log(index);
    }
  };

  const nameChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    /////////////JURY blockchain side //////////////
    chainCreateJuryBet()
    /////////////STANDARD blockchain side //////////////
    chainCreateStandardBet()
  };
  // const BetCreated = () => {
  //   if (opponent !== "pending") {
  //     navigate(`/my-open-bets/${betid}`, { state: { bet: betid } });
  //     console.log("betCreated ran")
  //   }
  // }
  useEffect(() => {
    getData();
    btnfunc();
    StandardBetHandler();
  }, [btnfunc, index]);
  useEffect(() => {
    // BetCreated()
    const interval = setInterval(() => {
      getData();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <LoadingModal
        show={modalShowStandard || modalShowJury}
        data={`${dataModalStandard}${dataModalJury}`}
      />
        <Loading1 loaded={isLoading}>
      <Container className="mt-5 betcontainer">
        {(errMsgJury !== "" || errMsgStandard !== "") && AlertDanger(errMsgJury, errMsgStandard)}
        <Card className="text-center">
          <Form onSubmit={onSubmitHandler}>
            <Card.Header>
              You have been asked to participate in a dispute
              <FormGroup>
                <FormLabel>Your name:</FormLabel>
                <FormControl
                  onChange={nameChangeHandler}
                  type="text"
                  placeholder="Name"
                  name="name"
                  required
                />
              </FormGroup>
            </Card.Header>
            <Card.Body>
              {participant_type1 === "opponent" &&
                <Card.Title>You are disputing this statement:<br /><br />{description} </Card.Title>}
              {participant_type1 === "jury" &&
                <Card.Title>Description:{description} </Card.Title>}
              <Card.Text>The value at stake is: {amount} USD</Card.Text>
              <Row>
                <Col>
                  <Button className="me-4 mb-4 mt-4" variant="primary" disabled>
                    Creator & Opponent1:
                  </Button>
                  {creatorName} {creator}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button className="me-4" variant="primary" disabled>
                    Opponent2:
                  </Button>
                  {opponentName} {opponent}
                </Col>
              </Row>
              <br />
              {!isStandard && (
                <Row>
                  <Col>
                    <Button className="me-4" variant="primary" disabled>
                      Jury:
                    </Button>
                    {juryName} {jury}
                  </Col>
                </Row>
              )}
            </Card.Body>
            <Card.Footer className="card-footerborder">
              Limit Date: {finalDate}
            </Card.Footer>
            <Button
              type="submit"
              className={`ms-5 me-5 ${btn}`}
              variant="info" size="xs"
            >
              Accept duel
            </Button>
          </Form>
        </Card>
      </Container>
      </Loading1>
    </>
  );
}
export default BetAccept;