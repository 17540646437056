import React from "react";

import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";

import BetItem from "./BetItem";

BetsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      betid: PropTypes.number,
      bet_duration: PropTypes.instanceOf(Date),
      description: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
};

function BetsList({ items }) {
  return (
    (items && (
      <div className="container">
        <>
          <Table className="table-xcrow">
            <thead>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Description</th>
                <th>Value</th>
                <th>Status</th>
                <th>Expiration</th>
              </tr>
            </thead>
            <tbody>
              {items.map((bet) => (
                <BetItem key={bet.betid} {...bet} />
              ))}
            </tbody>
          </Table>
        </>
      </div>
    )) || <h2 className="bets-list__fallback"> Found no bets. </h2>
  );
}

export default BetsList;
