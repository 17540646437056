import React, { useState } from "react";

import PropTypes from "prop-types";
import { Button, Modal, Image } from "react-bootstrap";


PopQR.propTypes = {
    btn: PropTypes.string,
    qrImage: PropTypes.string,
    data: PropTypes.string
};

function PopQR({ btn, qrImage, data }) {

    const [showqr, setShowqr] = useState(false);

    const handleClose = () => {
        setShowqr(false);
    }
    const handleShow_qr = () => {
        setShowqr(true);
    };

    return (
        <>
            <Button
                className={`${btn} mt-4 marginbtnstop`}
                variant="primary"
                onClick={handleShow_qr}
            >
                QR {data}
            </Button>

            <Modal size="lg" show={showqr} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Share this QR Code with {data}:</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="qr-popup">
                        <Image src={qrImage} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PopQR;