import { useContext, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import contractAddress from "../abis/contract-address.json";
import ActiveWalletContext from "../components/Wallet/ActiveWallet.context";

import useApproveFunds from "./useApproveFunds";

export default function useStandardBetCreateForm(formValue, index, description, proxy) {

  const [validated, setValidated] = useState(false)

  const navigate = useNavigate();

  const navigateURL = `/bet_standard_share/`

  const addressBet = contractAddress.sbfactory;


  const authCtx = useContext(ActiveWalletContext);
  const wallet = authCtx.wallet;

  const { dataHook, errMsg, modalShow, setDataModal, checkFunds, approveFunds } = useApproveFunds(wallet, addressBet)

  const postUrl = "https://api.xcrow.net/bets/standard?wallet=" + wallet;
  const data = {
    creator: {
      name: formValue.bet_userName,
      value: Number(formValue.bet_value),
      wallet,
    },
    index_bet_a: index,
    bet_condition_a: formValue.bet_comparison,
    price_predicted: Number(formValue.bet_priceprediction),
    time_frame: formValue.bet_validity,
    bet_duration: new Date(formValue.bet_date).getTime(),
    description,
    participant_type: "opponent",
    contract_address: proxy,
  };

  const postData = async () => {
    setValidated(true);
    await axios
      .post(postUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        setTimeout(() => {
          navigate(`${navigateURL}${response.data.value}`, { state: { bet: response.data.value } })
        }, "3000")
      });
  }

  const ApproveFlow = async () => {
    try {
      const approvedfunds = await checkFunds()
      if (approvedfunds / 10 ** 18 >= formValue.bet_value) {
        setDataModal("Funds available, creating your dispute..")
        postData()
      } else {
        approveFunds(formValue.bet_value).then((response) => {
          if (response === true) {
            postData()
          }
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }


  return {
    errMsg,
    validated,
    dataHook,
    modalShow,
    ApproveFlow,
    postData
  }
}
