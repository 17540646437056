import { useState } from "react";

const usePositiveNumbers = () => {

    const [betvalor, setbetvalor] = useState(0);
    const [predictedValor, setPredictedValor] = useState("");

    const updateInputValue = (evt) => {
        const val = evt.target.value
            .replace(/[^.\d]/g, "")
            .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
        setbetvalor(val);
    };

    const updatePredictedValue = (evt) => {
        const val = evt.target.value
            .replace(/[^.\d]/g, "")
            .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
        setPredictedValor(val);
    };

    return {
        betvalor,
        predictedValor,
        updateInputValue,
        updatePredictedValue,
    };
}

export default usePositiveNumbers;